@let fetchedAt = $expensesFetchedAt | async; @let expenses = $expenses | async;

<mat-card>
  <mat-card-header class="d-flex justify-content-between">
    <mat-card-title>Expenses</mat-card-title>
    <div>
      <button
        (click)="onAddExpenseClicked()"
        mat-raised-button
        color="primary"
        type="submit"
        class="px-4"
      >
        Add Expense
      </button>
    </div>
  </mat-card-header>

  <mat-card-content>
    <div class="mb-3 small">
      
    </div>

    @if (!fetchedAt) {
    <div class="d-flex justify-content-center mt-4">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    }
    <!----->
    @if (fetchedAt && expenses?.length) {
    <table mat-table [dataSource]="expenses ?? []">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.description }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.value | currency:'R' }}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns;"
        (click)="onRowClicked(row.id)"
      ></tr>
    </table>
    }
    <!----->
    @if (fetchedAt && !expenses?.length) {
    <div class="text-center">
      <p>No expenses</p>
    </div>
    }
  </mat-card-content>
</mat-card>
