import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';

import { MatButtonModule } from '@angular/material/button';
import { DocumentType, User } from '@models';
import { UserService } from '@services';
import {
  fetchDocumentTypes,
  fetchUsers,
  selectDocumentTypes,
  selectUsers,
} from '@state';

interface Status {
  value: string;
  viewValue: string;
}

@Component({
  standalone: true,
  imports: [
    MatCardModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatIconModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './users.page.html',
  styleUrl: './users.page.scss',
})
export class UsersPage implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  // These should be enums
  public status: Status[] = [
    { value: 'approved', viewValue: 'Approved' },
    { value: 'rejected', viewValue: 'Rejected' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'incomplete', viewValue: 'Incomplete' },
  ];

  public columns: string[] = ['id', 'name', 'mobile', 'createdAt', 'status'];

  search: string = '';
  selectedStatus: string = '';
  dataSource = new MatTableDataSource<User>();
  private _liveAnnouncer = inject(LiveAnnouncer);
  private documentTypes: DocumentType[] = [];
  private users: User[] = [];

  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
    this.store.select(selectUsers).subscribe((users) => {
      this.users = users.filter((user) => user.role != 'admin');
      this.filterUsers();
    });

    this.store.select(selectDocumentTypes).subscribe((documentTypes) => {
      this.documentTypes = documentTypes;
    });

    this.store.dispatch(fetchUsers());
    this.store.dispatch(fetchDocumentTypes());
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public filterUsers() {
    let users = this.users;

    if (this.selectedStatus) {
      users = users.filter(
        (user) => this.getUserStatus(user) === this.selectedStatus
      );
    }

    if (this.search) {
      const search = this.search.toLowerCase();
      users = this.users.filter(
        (user) =>
          user.profile?.idNumber?.toLowerCase().includes(search) ||
          user.profile?.firstName.toLowerCase().includes(search) ||
          user.profile?.lastName.toLowerCase().includes(search) ||
          user.mobile?.toLowerCase().includes(search)
      );
    }

    this.dataSource.data = users;
  }

  public getUserStatus(user: User): string {
    return this.isUserApproved(user) ? 'approved' : 'incomplete';
  }

  public clearFilter() {
    this.search = '';
    this.selectedStatus = '';
    this.filterUsers();
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  public navToUserDetails(userId: string) {
    this.router.navigate(['/users', userId]);
  }

  public isUserApproved(user: User): boolean {
    return UserService.isUserApproved(user, this.documentTypes);
  }
}
