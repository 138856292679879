import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';

import { MatButtonModule } from '@angular/material/button';
import { Incident } from '@models';
import { fetchIncidents, selectIncidents } from '@state';

@Component({
  standalone: true,
  imports: [
    MatCardModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatIconModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './incidents.page.html',
  styleUrl: './incidents.page.scss',
})
export class IncidentsPage implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  columns: string[] = ['user', 'incidentType', 'createdAt', 'updatedAt'];

  search: string = '';
  dataSource = new MatTableDataSource<Incident>();
  private _liveAnnouncer = inject(LiveAnnouncer);

  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
    this.store.select(selectIncidents).subscribe((incidents) => {
      this.dataSource.data = incidents;
    });

    this.store.dispatch(fetchIncidents());
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public filterIncidents() {
    this.search = this.search.toLowerCase();
    this.store
      .select(selectIncidents)
      .pipe(
        map((incidents) =>
          incidents.filter(
            (incident) =>
              incident.id?.toLowerCase().includes(this.search) ||
              incident.type?.toLowerCase().includes(this.search)
          )
        )
      )
      .subscribe(
        (filteredIncidents) => (this.dataSource.data = filteredIncidents)
      );
  }

  public clearFilter() {
    this.search = '';
    this.store.select(selectIncidents).subscribe((incidents) => {
      this.dataSource.data = incidents;
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  navToIncidentDetails(incidentId: string) {
    this.router.navigate(['/incidents', incidentId]);
  }
}
