import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Expense } from '@models';
import { ExpenseState } from '@state';
import { RouteState, selectRouteState } from '@state/route';

export const selectExpenseState =
  createFeatureSelector<ExpenseState>('expense');

export const selectExpenses = createSelector(
  selectExpenseState,
  (state: ExpenseState) => {
    return state.expenses ?? [];
  }
);

export const selectCurrentExpense = createSelector(
  selectExpenses,
  selectRouteState,
  (expenses: Expense[], route: RouteState) => {
    return expenses.find((x) => x.id == route.params['expenseId']);
  }
);

export const selectExpensesFetchedAt = createSelector(
  selectExpenseState,
  (state: ExpenseState) => state.fetchedAt
);

export const selectCurrentUserExpenses = createSelector(
  selectExpenseState,
  selectRouteState,
  (state: ExpenseState, route: RouteState) => {
    return state.userExpenses[route.params['userId']] ?? [];
  }
);
