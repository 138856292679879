export const environment = {
  production: false,
  apiUrl: 'https://qa.route-wise-api.devit.technology/api',
  firebase: {
    apiKey: 'AIzaSyDePlybReZHw4BNcHcVDYdSbQWwS4Kqk2g',
    authDomain: 'route-wise-2024.firebaseapp.com',
    databaseURL: 'https://route-wise-2024-default-rtdb.firebaseio.com',
    projectId: 'route-wise-2024',
    storageBucket: 'route-wise-2024.firebasestorage.app',
    messagingSenderId: '770924155988',
    appId: '1:770924155988:web:0352d2d915f2b5046b49c1',
    measurementId: 'G-91VLFFDEEN',
  },
};
