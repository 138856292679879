import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Expense, User } from '@models';
import { Store } from '@ngrx/store';
import { UtilService } from '@services';
import { fetchCurrentUserExpenses } from '@state';
import {
  createUserExpense,
  createUserExpenseFailed,
  createUserExpenseSuccess,
  deleteUserExpense,
  deleteUserExpenseFailed,
  deleteUserExpenseSuccess,
  fetchExpenses,
  selectCurrentUserExpenses,
  selectExpenses,
  selectExpensesFetchedAt,
} from '@state/expense';

@Component({
  selector: 'app-user-expenses',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
  ],
  templateUrl: './user-expenses.component.html',
  styleUrl: './user-expenses.component.scss',
})
export class UserExpensesComponent implements OnInit, OnDestroy {
  @Input() user: User | null = null;

  displayedColumns: string[] = ['date', 'images', 'comment'];

  $expenses = this.store.select(selectExpenses);
  $fetched = this.store.select(selectExpensesFetchedAt);
  loadingExpenseId = '';
  error = '';

  private userExpenses: Expense[] = [];
  private sub: Subscription | null = null;

  constructor(
    private store: Store,
    private router: Router,
    private utils: UtilService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(fetchExpenses());
    this.store.dispatch(fetchCurrentUserExpenses());

    this.sub = this.store.select(selectCurrentUserExpenses).subscribe((x) => {
      this.userExpenses = x;
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  async onToggleExpense(expense: Expense): Promise<void> {
    this.loadingExpenseId = expense.id ?? '';
    this.error = '';

    try {
      if (this.doesUserHaveExpense(expense)) {
        await this.deleteUserExpense(expense);
      } else {
        await this.addUserExpense(expense);
      }
    } catch (err) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loadingExpenseId = '';
  }

  private async deleteUserExpense(expense: Expense): Promise<void> {
    await this.utils.actionsToPromise(
      deleteUserExpense({ userId: this.user!.id!, expenseId: expense.id! }),
      deleteUserExpenseSuccess,
      deleteUserExpenseFailed
    );
  }

  private async addUserExpense(expense: Expense): Promise<void> {
    await this.utils.actionsToPromise(
      createUserExpense({ userId: this.user!.id!, expenseId: expense.id! }),
      createUserExpenseSuccess,
      createUserExpenseFailed
    );
  }

  doesUserHaveExpense(expense: Expense): boolean {
    return this.userExpenses.some((x) => x.id === expense.id);
  }

  public onNavToExpensesClicked(): void {
    this.router.navigate(['expenses']);
  }
}
