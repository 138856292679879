import { User } from '@models';

export interface UserState {
  users: User[];
  selectedMapUserIds: string[];
  fetchedAt: Date | null;
}

export const initialUserState: UserState = {
  users: [],
  selectedMapUserIds: [],
  fetchedAt: null,
};
