import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import {
  AddressService,
  DocumentService,
  ProfileService,
  UserService,
} from '@services';
import { selectRouteState } from '@state/route';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private userService: UserService,
    private documentService: DocumentService,
    private addressService: AddressService,
    private profileService: ProfileService
  ) {}

  fetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchUsers),
      switchMap(() =>
        from(this.userService.get()).pipe(
          map((users) => UserActions.fetchUsersSuccess({ users })),
          catchError((error) => of(UserActions.fetchUsersFailed({ error })))
        )
      )
    )
  );

  fetchUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchUserById),
      switchMap(({ userId }) =>
        from(this.userService.getById(userId)).pipe(
          map((user) => UserActions.fetchUserByIdSuccess({ user })),
          catchError((error) => of(UserActions.fetchUserByIdFailed({ error })))
        )
      )
    )
  );

  fetchCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchCurrentUser),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const userId = route.params['userId'];
        return of(UserActions.fetchUserById({ userId }));
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      switchMap(({ user }) =>
        from(this.userService.update(user)).pipe(
          map((user) => UserActions.updateUserSuccess({ user })),
          catchError((error) => of(UserActions.updateUserFailed({ error })))
        )
      )
    )
  );

  setUserDocumentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setUserDocumentStatus),
      switchMap(({ document, status }) =>
        from(this.documentService.setDocumentStatus(document, status)).pipe(
          map((document) =>
            UserActions.setUserDocumentStatusSuccess({ document })
          ),
          catchError((error) =>
            of(UserActions.setUserDocumentStatusFailed({ error }))
          )
        )
      )
    )
  );

  setUserProfileStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setUserProfileStatus),
      switchMap(({ profile, status }) =>
        from(this.profileService.setProfileStatus(profile, status)).pipe(
          map((profile) => UserActions.setUserProfileStatusSuccess({ profile })),
          catchError((error) =>
            of(UserActions.setUserProfileStatusFailed({ error }))
          )
        )
      )
    )
  );

  setUserAddressStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setUserAddressStatus),
      switchMap(({ address, status }) =>
        from(this.addressService.setAddressStatus(address, status)).pipe(
          map((address) => UserActions.setUserAddressStatusSuccess({ address })),
          catchError((error) =>
            of(UserActions.setUserAddressStatusFailed({ error }))
          )
        )
      )
    )
  );

  createCarTrackProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createCarTrackProfile),
      switchMap(({ user }) =>
        from(this.userService.createCarTrackProfile(user)).pipe(
          map((user) => UserActions.createCarTrackProfileSuccess({ user })),
          catchError((error) =>
            of(UserActions.createCarTrackProfileFailed({ error }))
          )
        )
      )
    )
  );
}
