import { createReducer, on } from '@ngrx/store';

import { StateUtils } from '@state/state-utils';
import * as InspectionActions from './inspection.actions';
import { initialInspectionState, InspectionState } from './inspection.state';

const inspectionReducer = createReducer<InspectionState>(
  initialInspectionState,

  on(InspectionActions.fetchInspectionsSuccess, (state, { inspections }) => ({
    ...state,
    inspections: StateUtils.combineStateArr(state.inspections, inspections),
    fetchedAt: new Date(),
  })),
  on(InspectionActions.fetchInspectionByIdSuccess, (state, { inspection }) => ({
    ...state,
    inspections: StateUtils.combineState(state.inspections, inspection),
    fetchedAt: new Date(),
  })),
  on(
    InspectionActions.fetchInspectionsFailed,
    InspectionActions.fetchInspectionByIdFailed,
    (state, { error }) => ({
      ...state,
      fetchedAt: new Date(),
    })
  )
);

export { inspectionReducer };
