import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { fetchVehicles, selectVehicles, selectVehiclesFetchedAt } from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './vehicles.page.html',
  styleUrl: './vehicles.page.scss',
})
export class VehiclesPage implements OnInit {
  columns: string[] = ['make', 'model', 'year', 'color', 'vin', 'registration', 'user'];

  $vehicles = this.store.select(selectVehicles);
  $vehiclesFetchedAt = this.store.select(selectVehiclesFetchedAt);

  constructor(private store: Store, private router: Router) {}

  public ngOnInit(): void {
    this.store.dispatch(fetchVehicles());
  }

  public navToVehicleDetails(vehicleId: string): void {
    this.router.navigate(['/vehicles', vehicleId]);
  }
}
