import { InspectionImageType } from '@models';

export interface InspectionImageTypeState {
  inspectionImageTypes: InspectionImageType[];
  fetchedAt: Date | null;
}

export const initialInspectionImageTypeState: InspectionImageTypeState = {
  inspectionImageTypes: [],
  fetchedAt: null,
};
