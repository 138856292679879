import { CarTrackEventType, DriverBehaviorMetric } from '@enums';
import { VehicleEvent } from '@models';

export abstract class DriverMetricsUtils {
  public static buildRadarChatData(vehicleEvents: VehicleEvent[]): any[] {
    let collisionCount = 0;
    let shockCount = 0;
    let harshBreakingCount = 0;
    let harshAccelerationCount = 0;
    let harshCorneringCount = 0;
    let speedingCount = 0;
    vehicleEvents?.forEach((event) => {
      switch (event.eventDescription) {
        case CarTrackEventType.FORWARD_COLLISION_WARNING:
        case CarTrackEventType.PEDESTRIAN_COLLISION_WARNING:
        case CarTrackEventType.FORWARD_COLLISION_WARNING:
          collisionCount++;
          break;
        case CarTrackEventType.SHOCK:
          shockCount++;
          break;
        case CarTrackEventType.HARSH_BRAKING:
          harshBreakingCount++;
          break;
        case CarTrackEventType.HARSH_ACCELERATION:
          harshAccelerationCount++;
          break;
        case CarTrackEventType.HARSH_CORNERING:
          harshCorneringCount++;
          break;
        case CarTrackEventType.SPEEDING_START:
          speedingCount++;
          break;
      }
    });

    return [
      {
        label: 'Collisions',
        metric: DriverBehaviorMetric.COLLISION,
        count: collisionCount,
      },
      {
        label: 'Shock',
        metric: DriverBehaviorMetric.SHOCK,
        count: shockCount,
      },
      {
        label: 'Harsh Breaking',
        metric: DriverBehaviorMetric.HARSH_BRAKING,
        count: harshBreakingCount,
      },
      {
        label: 'Harsh Acceleration',
        metric: DriverBehaviorMetric.HARSH_ACCELERATION,
        count: harshAccelerationCount,
      },
      {
        label: 'Harsh Cornering',
        metric: DriverBehaviorMetric.HARSH_CORNERING,
        count: harshCorneringCount,
      },
      {
        label: 'Speeding',
        metric: DriverBehaviorMetric.SPEEDING,
        count: speedingCount,
      },
    ];
  }

  public static buildLineChartData(vehicleEvents: VehicleEvent[]): any[] {
    const map = new Map();
    vehicleEvents.forEach((event) => {
      switch (event.eventDescription) {
        case CarTrackEventType.FORWARD_COLLISION_WARNING:
        case CarTrackEventType.PEDESTRIAN_COLLISION_WARNING:
        case CarTrackEventType.FORWARD_COLLISION_WARNING:
        case CarTrackEventType.SHOCK:
        case CarTrackEventType.HARSH_BRAKING:
        case CarTrackEventType.HARSH_ACCELERATION:
        case CarTrackEventType.HARSH_CORNERING:
        case CarTrackEventType.SPEEDING_START:
          const date = new Date(event.eventTS).toLocaleDateString();
          const value = map.get(date);
          if (value) {
            map.set(date, value + 1);
          } else {
            map.set(date, 1);
          }
      }
    });
    const res: any[] = [];
    map.forEach((value, key) => res.push({ label: key, count: value }));
    return res;
  }

  public static calculateDriverBehaviorMetrics(data: any[]): any {
    const minValue = 0;
    // const maxValue = 30;
    const maxValue = Math.max(...data.map((item) => item.count));
    const days = 30;
    const metricCounts = data.reduce((acc, { metric, count }) => {
      acc[metric] = count;
      return acc;
    }, {});
    const collisionScore =
      1 - ((metricCounts[DriverBehaviorMetric.COLLISION] || 0) / days) * 6;
    const shockScore =
      1 - ((metricCounts[DriverBehaviorMetric.SHOCK] || 0) / days) * 1;
    const harshBrakingScore =
      1 - ((metricCounts[DriverBehaviorMetric.HARSH_BRAKING] || 0) / days) * 1;
    const harshAccelerationScore =
      1 -
      ((metricCounts[DriverBehaviorMetric.HARSH_ACCELERATION] || 0) / days) * 1;
    const harshCorneringScore =
      1 -
      ((metricCounts[DriverBehaviorMetric.HARSH_CORNERING] || 0) / days) * 1;
    const speedingScore =
      1 - ((metricCounts[DriverBehaviorMetric.SPEEDING] || 0) / days) * 3;

    let driverScore = Math.round(
      ((collisionScore +
        shockScore +
        harshBrakingScore +
        harshAccelerationScore +
        harshCorneringScore +
        speedingScore) /
        6) *
        100
    );
    driverScore = Math.max(1, driverScore);
    return { driverScore, minValue, maxValue };
  }

  public static getColorForValue(
    value: number,
    min: number,
    max: number
  ): string {
    const ratio = (value - min) / (max - min);
    const red = Math.min(255, 2 * ratio * 255);
    const green = Math.min(255, 2 * (1 - ratio) * 255);
    const color = `rgb(${red},${green}, 0)`;
    return color;
  }
}
