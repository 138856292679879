import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { DisplayInputComponent } from '@components';
import { Status } from '@enums';
import { Document, DocumentType, User } from '@models';
import { UtilService } from '@services';
import * as UserActions from '@state/user/user.actions';

@Component({
  selector: 'app-user-documents',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    DisplayInputComponent,
    MatProgressSpinnerModule,
    DisplayInputComponent,
  ],
  templateUrl: './user-documents.component.html',
  styleUrl: './user-documents.component.scss',
})
export class UserDocumentsComponent implements OnChanges {
  @Input() user: User | null = null;

  public doc: Document = new Document();
  public docType: DocumentType = new DocumentType();

  public documents: Document[] = [];
  public documentIndex: number = 0;
  public documentUrl: SafeResourceUrl | string = '';

  public loading: boolean = false;
  public error: string = '';

  constructor(private sanitizer: DomSanitizer, private utils: UtilService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user && this.user.documents && this.user.documents.length) {
      this.documents = this.user.documents;
      this.populateDocumentAndDocumentType();
    }
  }

  private populateDocumentAndDocumentType(): void {
    this.doc = this.documents[this.documentIndex] ?? new Document();
    this.docType = this.doc.documentType ?? new DocumentType();

    const url = this.doc?.downloadUrl ?? '';
    this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public nextDocument(): void {
    this.documentIndex = (this.documentIndex + 1) % this.documents.length;
    this.populateDocumentAndDocumentType();
  }

  public previousDocument(): void {
    const size = this.documents.length;
    const index = this.documentIndex;
    this.documentIndex = (index + size - 1) % size;
    this.populateDocumentAndDocumentType();
  }

  public documentStatus(): string {
    if (this.doc.status === Status.PENDING) return 'Pending';
    if (this.doc.status === Status.APPROVED) return 'Approved';
    if (this.doc.status === Status.DECLINED) return 'Declined';
    return 'Unknown';
  }

  public getStatusIcon(): string {
    if (this.doc.status === Status.PENDING) return 'pending';
    if (this.doc.status === Status.APPROVED) return 'check_circle';
    if (this.doc.status === Status.DECLINED) return 'cancel';
    return '';
  }

  public async onApproveDocumentClicked(): Promise<void> {
    this.saveDocumentStatus(Status.APPROVED);
  }

  public async onDeclineDocumentClicked(): Promise<void> {
    this.saveDocumentStatus(Status.DECLINED);
  }

  private async saveDocumentStatus(status: Status): Promise<void> {
    this.loading = true;
    try {
      await this.utils.actionsToPromise(
        UserActions.setUserDocumentStatus({ document: this.doc, status }),
        UserActions.setUserDocumentStatusSuccess,
        UserActions.setUserDocumentStatusFailed
      );
    } catch (err) {
      this.error = this.utils.getErrorMessage(err);
    }
    this.loading = false;
  }
}
