import { environment } from '../../../environment/environment';

import { BaseModel } from '../../models/base.model';
import { HttpService } from '../http/http.service';

export abstract class CrudService<T extends BaseModel> {
  protected url: string = '';

  constructor(private endpoint: string, protected http: HttpService) {
    this.url = `${environment.apiUrl}/${this.endpoint}`;
  }

  async get(): Promise<T[]> {
    const response = await this.http.get<T[]>(`${this.url}/all`);
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  async getById(id: string): Promise<T> {
    const response = await this.http.get<T>(`${this.url}/${id}`);
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  async create(item: T): Promise<T> {
    const response = await this.http.post<T>(this.url, item);
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  async update(item: T): Promise<T> {
    const response = await this.http.put<T>(`${this.url}/${item.id}`, item);
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  async delete(id: string): Promise<string> {
    const response = await this.http.delete<void>(`${this.url}/${id}`);
    if (!response.success) throw new Error(response.message);
    return response.message;
  }
}
