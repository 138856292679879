import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Inspection } from '@models';
import { InspectionState } from '@state';
import { RouteState, selectRouteState } from '@state/route';

export const selectInspectionState =
  createFeatureSelector<InspectionState>('inspection');

export const selectInspections = createSelector(
  selectInspectionState,
  (state: InspectionState) => {
    return state.inspections ?? [];
  }
);

export const selectCurrentUserInspections = createSelector(
  selectInspections,
  selectRouteState,
  (inspections: Inspection[], route: RouteState) => {
    return inspections.filter((x) => x.userId == route.params['userId']);
  }
);

export const selectInspectionsFetchedAt = createSelector(
  selectInspectionState,
  (state: InspectionState) => state.fetchedAt
);
