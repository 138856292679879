@let inspections = ($inspections | async) || [];
<!---->
@let fetched = ($fetched | async);
<!---->

@if (!fetched) {
<div class="d-flex justify-content-between align-items-center">
  <mat-progress-spinner mode="indeterminate" color="primary" diameter="30" />
  <div class="ms-2">Fetching inspections...</div>
</div>
}

<div class="d-flex flex-column">
  <div class="table-container">
    <table mat-table [dataSource]="inspections" class="mat-elevation-z8">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date }}
        </td>
      </ng-container>
      <ng-container matColumnDef="images">
        <th mat-header-cell *matHeaderCellDef>Images</th>
        <td mat-cell *matCellDef="let element">
          @for (image of element.images; track $index;) {
          <img [src]="image.imageUrl" class="inspection-image" />
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef>Comment</th>
        <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
