import { createAction, props } from '@ngrx/store';

import { InspectionImageType } from '@models';

// Create Inspection Image Type:
export const createInspectionImageType = createAction(
  '[InspectionImageType] Create Initiated',
  props<{ inspectionImageType: InspectionImageType }>()
);
export const createInspectionImageTypeSuccess = createAction(
  '[InspectionImageType] Create Success',
  props<{ inspectionImageType: InspectionImageType }>()
);
export const createInspectionImageTypeFailed = createAction(
  '[InspectionImageType] Create Failed',
  props<{ error: Error }>()
);

// Fetch Inspection Image Types:
export const fetchInspectionImageTypes = createAction(
  '[InspectionImageType] Fetch Initiated'
);
export const fetchInspectionImageTypesSuccess = createAction(
  '[InspectionImageType] Fetch Success',
  props<{ inspectionImageTypes: InspectionImageType[] }>()
);
export const fetchInspectionImageTypesFailed = createAction(
  '[InspectionImageType] Fetch Failed',
  props<{ error: Error }>()
);

// Fetch Inspection Image Type By Id:
export const fetchInspectionImageTypeById = createAction(
  '[InspectionImageType] Fetch By Id Initiated',
  props<{ id: string }>()
);
export const fetchCurrentInspectionImageType = createAction(
  '[InspectionImageType] Fetch Current By Id Initiated'
);
export const fetchInspectionImageTypeByIdSuccess = createAction(
  '[InspectionImageType] Fetch By Id  Success',
  props<{ inspectionImageType: InspectionImageType | null }>()
);
export const fetchInspectionImageTypeByIdFailed = createAction(
  '[InspectionImageType] Fetch By Id  Failed',
  props<{ error: Error }>()
);

// Update Inspection Image Type:
export const updateInspectionImageType = createAction(
  '[InspectionImageType] Update Initiated',
  props<{ inspectionImageType: InspectionImageType }>()
);
export const updateInspectionImageTypeSuccess = createAction(
  '[InspectionImageType] Update Success',
  props<{
    inspectionImageType: InspectionImageType;
  }>()
);
export const updateInspectionImageTypeFailed = createAction(
  '[InspectionImageType] Update Failed',
  props<{ error: Error }>()
);

// Delete Inspection Image Type:
export const deleteInspectionImageType = createAction(
  '[InspectionImageType] Delete Initiated',
  props<{ id: string }>()
);
export const deleteInspectionImageTypeSuccess = createAction(
  '[InspectionImageType] Delete Success',
  props<{ id: string }>()
);
export const deleteInspectionImageTypeFailed = createAction(
  '[InspectionImageType] Delete Failed',
  props<{ error: Error }>()
);
