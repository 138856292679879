import { BaseModel } from './base.model';

export class Expense extends BaseModel {
  name: string = '';
  description: string = '';
  value: number = 0;

  constructor(data?: Partial<Expense>) {
    super();
    Object.assign(this, data);
  }
}
