import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';

import { Incident, IncidentImage } from '@models';
import {
  fetchCurrentIncident,
  selectCurrentIncident,
  selectIncidentsFetchedAt,
} from '@state';
import { IncidentImagesComponent } from './incident-images/incident-images.component';
import { IncidentSummeryComponent } from './incident-summary/incident-summary.component';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    IncidentSummeryComponent,
    IncidentImagesComponent,
  ],
  templateUrl: './incident-details.page.html',
  styleUrl: './incident-details.page.scss',
})
export class IncidentDetailsPage implements OnInit {
  public $incident = this.store.select(selectCurrentIncident);
  public $incidentsFetched = this.store.select(selectIncidentsFetchedAt);

  public currentTab: string = 'details';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(fetchCurrentIncident());
  }

  public setTab(tab: string): void {
    this.currentTab = tab;
  }

  public returnImageByType(
    incident: Incident | null,
    type: string
  ): IncidentImage[] {
    if (!incident || !incident.images) return [];
    return incident.images.filter((x) => x.type === type);
  }
}
