<google-map height="85vh" width="100%" [center]="center" [zoom]="zoom">
  <map-marker
    *ngFor="let driver of drivers"
    [position]="driver.location"
    [icon]="motorbikeIcon"
    [title]="driver.id"
    (mapClick)="onMarkerClick(driver.id)"
    #marker="mapMarker"
  >
  </map-marker>
  <map-info-window
    *ngFor="let driverId of selectedDriverIds"
    [position]="getDriverLocation(driverId)"
    (closeclick)="onInfoWindowClose(driverId)"
  >
    <div>
      <p><strong>name:</strong> {{ getDriverName(driverId) }}</p>
      <p><strong>ID:</strong> {{ driverId }}</p>
      <p>
        <strong>Location:</strong> {{ getDriverLocation(driverId).lat }}, {{
        getDriverLocation(driverId).lng }}
      </p>
      <!-- Add more driver details as needed -->
    </div>
  </map-info-window>
</google-map>
