@let vehicle = $vehicle | async; @let users = ($users | async) || [];
<div class="container">
  <mat-card>
    <mat-card-content>
      @if (vehicle) {
      <display-input title="Make" [value]="vehicle.manufacturer" />
      <display-input title="Model" [value]="vehicle.model" />
      <display-input title="Year" [value]="vehicle.year" />
      <display-input title="Color" [value]="vehicle.color" />
      <display-input title="Vin" [value]="vehicle.vin" />
      <display-input
        title="Registration Number"
        [value]="vehicle.registration"
      />
      }

      <hr class="my-4" />

      @if (vehicle?.user) {

      <div>
        <display-input
          title="Driver"
          [value]="vehicle?.user?.profile?.firstName + ' ' + vehicle?.user?.profile?.lastName"
        />

        <div class="d-flex justify-content-end align-items-center">
          @if (loading) {
          <mat-progress-spinner
            class="me-2"
            mode="indeterminate"
            color="primary"
            diameter="30"
          />
          }
          <button
            (click)="onRemoveDriverClicked(vehicle!)"
            mat-raised-button
            color="primary"
            class="px-4"
            [disabled]="loading"
          >
            Remove Driver
          </button>
        </div>
      </div>

      } @else {

      <div>
        <mat-form-field class="w-100">
          <mat-label>Available Driver</mat-label>
          <mat-select [(ngModel)]="userId" name="userId">
            @for (user of users; track user) {
            <mat-option [value]="user.id">
              {{ user.profile?.firstName }} {{ user.profile?.lastName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div class="d-flex justify-content-end align-items-center">
          @if (loading) {
          <mat-progress-spinner
            class="me-2"
            mode="indeterminate"
            color="primary"
            diameter="30"
          />
          }
          <button
            (click)="onAssignDriverClicked(vehicle?.id!, userId)"
            mat-raised-button
            color="primary"
            class="px-4"
            [disabled]="loading"
          >
            Assign Driver
          </button>
        </div>
      </div>
      } @if (error) {
      <app-alert (close)="error = ''" class="mt-3" type="error">
        {{error}}
      </app-alert>
      } @if (!($vehiclesFetchedAt | async)) {
      <mat-spinner></mat-spinner>
      }
    </mat-card-content>
  </mat-card>
</div>
