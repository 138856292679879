import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';

import { User } from '@models';
import { Store } from '@ngrx/store';
import {
  fetchCurrentUserInspections,
  selectCurrentUserInspections,
  selectInspectionsFetchedAt,
} from '@state/inspection';

@Component({
  selector: 'app-user-inspections',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './user-inspections.component.html',
  styleUrl: './user-inspections.component.scss',
})
export class UserInspectionsComponent implements OnInit {
  @Input() user: User | null = null;

  displayedColumns: string[] = ['date', 'images', 'comment'];

  $inspections = this.store.select(selectCurrentUserInspections);
  $fetched = this.store.select(selectInspectionsFetchedAt);

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(fetchCurrentUserInspections());
  }
}
