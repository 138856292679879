@if (vehicle) {
<div class="mt-3">
  <display-input title="Make" [value]="vehicle.manufacturer" />
  <display-input title="Model" [value]="vehicle.model" />
  <display-input title="Year" [value]="vehicle.year" />
  <display-input title="Color" [value]="vehicle.color" />
  <display-input title="Vin" [value]="vehicle.vin" />
  <display-input title="Registration Number" [value]="vehicle.registration" />
</div>
}

<div class="d-flex justify-content-center align-items-center h-100">
  <div class="text-center">
    @if (!carTrackDriverId) {
    <div class="gray mb-3">No Car Track Profile</div>
    <button
      (click)="onCreateDriverProfileClicked()"
      mat-raised-button
      color="secondary"
      class="px-4"
    >
      Create Car Track Profile
    </button>

    } @if (carTrackDriverId && !vehicle) {

    <div class="gray mb-3">No Vehicle Assigned</div>
    <button
      (click)="onAssignDriverClicked()"
      mat-raised-button
      color="primary"
      class="px-4"
    >
      Assign Vehicle
    </button>
    }
  </div>
</div>
