import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

import { interval } from 'rxjs';
import { endWith, map, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[appCountUp]',
  standalone: true,
})
export class CountUpDirective implements OnChanges {
  @Input('appCountUp') countTo: number = 0;
  @Input() duration: number = 2000;

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    this.startCountUp();
  }

  private startCountUp() {
    const startTime = Date.now();
    const endTime = startTime + this.duration;
    const stepTime = 1000 / 60; // 60 frames per second

    interval(stepTime)
      .pipe(
        map(() => {
          const now = Date.now();
          const progress = Math.min((now - startTime) / this.duration, 1);
          return Math.round(progress * this.countTo);
        }),
        takeWhile((value) => value < this.countTo),
        endWith(this.countTo)
      )
      .subscribe((value) => {
        this.el.nativeElement.textContent = value;
      });
  }
}
