import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { Store } from '@ngrx/store';

import * as AuthActions from '../../state/auth/auth.actions';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

interface MenuItem {
  title: string;
  icon: string;
  link: string;
}

@Component({
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    RouterLink,
    RouterLinkActive,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    BreadcrumbsComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit {
  opened: boolean = true;
  menuItems: MenuItem[] = [
    { title: 'Dashboard', icon: 'dashboard', link: '/dashboard' },
    { title: 'Users', icon: 'people', link: '/users' },
    { title: 'Document Types', icon: 'description', link: '/document-types' },
    {
      title: 'Inspection Images',
      icon: 'image',
      link: '/inspection-image-types',
    },
    { title: 'Expenses', icon: 'request_quote', link: '/expenses' },
    { title: 'Vehicles', icon: 'moped', link: '/vehicles' },
    { title: 'Map', icon: 'map', link: '/map' },
    { title: 'Incidents', icon: 'report_problem', link: '/incidents' },
    { title: 'Actions', icon: 'touch_app', link: '/actions' },
  ];

  activeItem: MenuItem | undefined;

  constructor(
    private store: Store,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeItem = this.menuItems.find(
          (item) => item.link === event.url
        );
      }
    });

    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Handset])
      .subscribe((result) => {
        result.matches ? (this.opened = false) : (this.opened = true);
      });
  }

  public setActiveItem(item: MenuItem) {
    this.activeItem = item;
  }

  public isActive(link: string): boolean {
    if (link === '/') {
      return this.router.url === link;
    }

    return this.router.url.startsWith(link);
  }

  public trackByLink(index: number, item: MenuItem): string {
    return item.link;
  }

  public onLogoutClicked() {
    this.store.dispatch(AuthActions.logout());
    this.router.navigate(['auth', 'login']);
  }
}
