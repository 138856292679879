import { createReducer, on } from '@ngrx/store';

import { Document, User } from '@models';
import { StateUtils } from '@state/state-utils';
import { removeUserFromVehicleSuccess } from '@state/vehicle';
import * as UserActions from './user.actions';
import { initialUserState, UserState } from './user.state';

const isUsersDocument = (user: User, document: Document): boolean => {
  const doc = (document as any) ?? {};
  const userId = doc.userId || doc.user?.id || doc.user;
  return user.id == userId;
};

const getUserWithUpdatedDocumentStatus = (
  user: User,
  document: Document
): User => {
  return {
    ...user,
    documents: user.documents?.map((doc) => {
      if (doc.id === document.id) {
        return { ...doc, status: document.status };
      } else {
        return doc;
      }
    }),
  };
};

const userReducer = createReducer<UserState>(
  initialUserState,

  on(UserActions.fetchUsersSuccess, (state, { users }) => ({
    ...state,
    users,
    fetchedAt: new Date(),
  })),
  on(UserActions.fetchUsersFailed, (state, { error }) => ({
    ...state,
    fetchedAt: new Date(),
  })),

  on(UserActions.fetchUserByIdSuccess, (state, { user }) => ({
    ...state,
    users: StateUtils.combineState(state.users, user),
    fetchedAt: new Date(),
  })),
  on(UserActions.fetchUserByIdFailed, (state, { error }) => ({
    ...state,
    fetchedAt: new Date(),
  })),

  on(UserActions.updateUserSuccess, (state, { user }) => ({
    ...state,
    users: StateUtils.combineState(state.users, user),
  })),

  on(UserActions.setSelectedMapUsers, (state, { users }) => ({
    ...state,
    selectedMapUserIds: users,
  })),

  on(UserActions.createCarTrackProfileSuccess, (state, { user }) => ({
    ...state,
    users: StateUtils.combineState(state.users, user),
  })),

  on(UserActions.setUserDocumentStatusSuccess, (state, { document }) => ({
    ...state,
    users: state.users.map((user) => {
      if (isUsersDocument(user, document)) {
        return getUserWithUpdatedDocumentStatus(user, document);
      }
      return user;
    }),
  })),

  on(UserActions.setUserProfileStatusSuccess, (state, { profile }) => ({
    ...state,
    users: state.users.map((user) => {
      if (user.id == profile.userId) return { ...user, profile };
      return user;
    }),
  })),

  on(UserActions.setUserAddressStatusSuccess, (state, { address }) => ({
    ...state,
    users: state.users.map((user) => {
      if (user.id == address.userId) return { ...user, address };
      return user;
    }),
  })),

  on(removeUserFromVehicleSuccess, (state, { vehicle }) => ({
    ...state,
    users: state.users.map((user) => {
      if (user.vehicle?.id == vehicle.id) {
        return { ...user, vehicle: undefined };
      }
      return user;
    }),
  }))
);

export { userReducer };
