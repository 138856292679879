@let tripsFetchedAt = $tripsFetchedAt | async;

<div>
  <mat-form-field>
    <mat-label>Date Range</mat-label>
    <mat-select
      [(value)]="selectedDateRange"
      (selectionChange)="filterByDateRange()"
    >
      @for(dateRange of dateRanges; track dateRange){
      <mat-option [value]="dateRange">{{ dateRange }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

@if(!!tripsFetchedAt){
<table mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ formatDateTime(element?.startTimestamp) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.startLocation || "N/A" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="end">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
    <td mat-cell *matCellDef="let element">
      {{ element.endLocation || "N/A" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="distance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Distance</th>
    <td mat-cell *matCellDef="let element">
      {{ element.tripDistance || "N/A" }} m
    </td>
  </ng-container>

  <ng-container matColumnDef="earnings">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver Earnings</th>
    <td mat-cell *matCellDef="let element">
      {{ element.driverIncome | currency : "R" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="tip">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver Tip</th>
    <td mat-cell *matCellDef="let element">
      {{ element.driverTip | currency : "R" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columns"
    (click)="navToTrip(row.id)"
  ></tr>

  <tr *matNoDataRow>
    <td colspan="4">
      <div class="no-data-message">No trips found.</div>
    </td>
  </tr>
</table>
} @if (!tripsFetchedAt) {
<div class="spinner-container">
  <mat-spinner></mat-spinner>
</div>
}
