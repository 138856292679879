<mat-card>
  <mat-card-content>
    <div class="d-flex justify-content-between">
      <mat-form-field appearance="outline" class="wider">
        <mat-label>Search</mat-label>
        <input
          matInput
          [(ngModel)]="search"
          (ngModelChange)="filterIncidents()"
          placeholder="Search"
        />
        <mat-icon
          class="pointer"
          matSuffix
          (click)="search = ''; filterIncidents()"
        >
          close
        </mat-icon>
      </mat-form-field>
    </div>

    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)"
    >
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell *matCellDef="let element">
          {{element.profile.firstName + ' ' + element.profile.lastName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="incidentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Incident Type</th>
        <td mat-cell *matCellDef="let element">{{element.type}}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let element">
          {{element.createdAt | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
        <td mat-cell *matCellDef="let element">
          {{element.updatedAt | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns;"
        (click)="navToIncidentDetails(row.id)"
      ></tr>

      <tr *matNoDataRow>
        <td colspan="4">
          <div class="no-data-message">No incidents found.</div>
        </td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
