import { createFeatureSelector, createSelector } from '@ngrx/store';

import { InspectionImageType } from '@models';
import { InspectionImageTypeState } from '@state';
import { RouteState, selectRouteState } from '@state/route';

export const selectInspectionImageTypeState =
  createFeatureSelector<InspectionImageTypeState>('inspectionImageType');

export const selectInspectionImageTypes = createSelector(
  selectInspectionImageTypeState,
  (state: InspectionImageTypeState) => {
    return state.inspectionImageTypes ?? [];
  }
);

export const selectCurrentInspectionImageType = createSelector(
  selectInspectionImageTypes,
  selectRouteState,
  (inspectionImageTypes: InspectionImageType[], route: RouteState) => {
    return inspectionImageTypes.find(
      (x) => x.id == route.params['inspectionImageTypeId']
    );
  }
);

export const selectInspectionImageTypesFetchedAt = createSelector(
  selectInspectionImageTypeState,
  (state: InspectionImageTypeState) => state.fetchedAt
);
