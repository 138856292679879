import { createAction, props } from '@ngrx/store';

import { DateRange } from '@enums';
import { Vehicle, VehicleEvent } from '@models';

// fetch vehicles
export const fetchVehicles = createAction('[Vehicle] Fetch Initiated');
export const fetchVehiclesSuccess = createAction(
  '[Vehicle] Fetch Success',
  props<{ vehicles: Vehicle[] }>()
);
export const fetchVehiclesFailed = createAction(
  '[Vehicle] Fetch Failed',
  props<{ error: Error }>()
);

// fetch vehicle by id
export const fetchVehicleById = createAction(
  '[Vehicle] Fetch By Id Initiated',
  props<{ vehicleId: string }>()
);
export const fetchCurrentVehicle = createAction(
  '[Vehicle] Fetch Current Vehicle Initiated'
);
export const fetchVehicleByIdSuccess = createAction(
  '[Vehicle] Fetch By Id Success',
  props<{ vehicle: Vehicle }>()
);
export const fetchVehicleByIdFailed = createAction(
  '[Vehicle] Fetch By Id Failed',
  props<{ error: Error }>()
);

// assign user to vehicle
export const assignUserToVehicle = createAction(
  '[Vehicle] Assign User To Vehicle',
  props<{ vehicleId: string; userId: string }>()
);
export const assignUserToVehicleSuccess = createAction(
  '[Vehicle] Assign User To Vehicle Success',
  props<{ vehicle: Vehicle }>()
);
export const assignUserToVehicleFailed = createAction(
  '[Vehicle] Assign User To Vehicle Failed',
  props<{ error: Error }>()
);

// remove user from vehicle
export const removeUserFromVehicle = createAction(
  '[Vehicle] Remove User From Vehicle',
  props<{ vehicleId: string; userId: string }>()
);
export const removeUserFromVehicleSuccess = createAction(
  '[Vehicle] Remove User From Vehicle Success',
  props<{ vehicle: Vehicle }>()
);
export const removeUserFromVehicleFailed = createAction(
  '[Vehicle] Remove User From Vehicle Failed',
  props<{ error: Error }>()
);

// fetch vehicle events
export const fetchVehicleEvents = createAction(
  '[Vehicle] Fetch Vehicle Events By Id Initiated',
  props<{ carTrackDriverId: string; dateRange: DateRange }>()
);
export const fetchVehicleEventsSuccess = createAction(
  '[Vehicle] Fetch Vehicle Events By Id Success',
  props<{ vehicleEvents: VehicleEvent[] }>()
);
export const fetchVehicleEventsFailed = createAction(
  '[Vehicle] Fetch Vehicle Events By Id Failed',
  props<{ error: Error }>()
);
