import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { ExpenseService } from '@services';
import { selectRouteState } from '@state/route';
// import * as ExpenseActions from './expense.actions';
import {
  createExpense,
  createExpenseFailed,
  createExpenseSuccess,
  createUserExpense,
  createUserExpenseFailed,
  createUserExpenseSuccess,
  deleteExpense,
  deleteExpenseFailed,
  deleteExpenseSuccess,
  deleteUserExpense,
  deleteUserExpenseFailed,
  deleteUserExpenseSuccess,
  fetchCurrentExpense,
  fetchCurrentUserExpenses,
  fetchExpenseById,
  fetchExpenseByIdFailed,
  fetchExpenseByIdSuccess,
  fetchExpenses,
  fetchExpensesByUserId,
  fetchExpensesByUserIdFailed,
  fetchExpensesByUserIdSuccess,
  fetchExpensesFailed,
  fetchExpensesSuccess,
  updateExpense,
  updateExpenseFailed,
  updateExpenseSuccess,
} from './expense.actions';

@Injectable()
export class ExpenseEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private expenseService: ExpenseService
  ) {}

  // Create
  createExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createExpense),
      switchMap(({ expense }) =>
        from(this.expenseService.create(expense)).pipe(
          map((expense) => createExpenseSuccess({ expense })),
          catchError((error) => of(createExpenseFailed({ error })))
        )
      )
    )
  );

  //Read
  fetchExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchExpenses),
      switchMap(() =>
        from(this.expenseService.get()).pipe(
          map((expenses) => fetchExpensesSuccess({ expenses })),
          catchError((error) => of(fetchExpensesFailed({ error })))
        )
      )
    )
  );

  fetchExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchExpenseById),
      switchMap(({ id }) =>
        from(this.expenseService.getById(id)).pipe(
          map((expense) => fetchExpenseByIdSuccess({ expense })),
          catchError((error) => of(fetchExpenseByIdFailed({ error })))
        )
      )
    )
  );

  fetchCurrentExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCurrentExpense),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const expenseId = route.params['expenseId'];

        if (expenseId == 'new') {
          return of(fetchExpenseByIdSuccess({ expense: null }));
        } else {
          return of(fetchExpenseById({ id: expenseId }));
        }
      })
    )
  );

  // Update
  updateExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateExpense),
      switchMap(({ expense }) =>
        from(this.expenseService.update(expense)).pipe(
          map((expense) => updateExpenseSuccess({ expense })),
          catchError((error) => of(updateExpenseFailed({ error })))
        )
      )
    )
  );

  // Delete
  deleteExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteExpense),
      switchMap(({ id }) =>
        from(this.expenseService.delete(id)).pipe(
          map(() => deleteExpenseSuccess({ id })),
          catchError((error) => of(deleteExpenseFailed({ error })))
        )
      )
    )
  );

  /* User Expenses */

  // create
  createUserExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserExpense),
      switchMap(({ userId, expenseId }) => {
        return from(this.expenseService.addUserExpense(userId, expenseId)).pipe(
          map((expense) => createUserExpenseSuccess({ expense, userId })),
          catchError((error) => of(createUserExpenseFailed({ error })))
        );
      })
    )
  );

  // read
  fetchExpensesByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchExpensesByUserId),
      switchMap(({ userId }) =>
        from(this.expenseService.getUserExpenses(userId)).pipe(
          map((expenses) => fetchExpensesByUserIdSuccess({ expenses, userId })),
          catchError((error) => of(fetchExpensesByUserIdFailed({ error })))
        )
      )
    )
  );

  fetchCurrentUserExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCurrentUserExpenses),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const userId = route.params['userId'];
        return of(fetchExpensesByUserId({ userId }));
      })
    )
  );

  // delete
  deleteUserExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserExpense),
      switchMap(({ userId, expenseId }) =>
        from(this.expenseService.deleteUserExpense(userId, expenseId)).pipe(
          map(() => deleteUserExpenseSuccess({ userId, expenseId })),
          catchError((error) => of(deleteUserExpenseFailed({ error })))
        )
      )
    )
  );
}
