import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DocumentType, User } from '@models';
import { UserService } from '@services';
import {
  RouteState,
  selectDocumentTypes,
  selectRouteState,
  UserState,
} from '@state';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUsers = createSelector(
  selectUserState,
  (state: UserState) => state.users
);

export const selectUsersElegibleForVehicle = createSelector(
  selectUsers,
  selectDocumentTypes,
  (users: User[], documentTypes: DocumentType[]) =>
    users.filter((user) =>
      UserService.isUserElegibleForVehicle(user, documentTypes)
    )
);

export const selectUsersFetchedAt = createSelector(
  selectUserState,
  (state: UserState) => state.fetchedAt
);

export const selectCurrentUser = createSelector(
  selectUsers,
  selectRouteState,
  (users: User[], route: RouteState) => {
    return users.find((x) => x.id === route.params['userId']);
  }
);

export const selectUserById = (userId: string) =>
  createSelector(selectUsers, (users: User[]) =>
    users.find((user) => user.id === userId)
  );

export const selectMapSelectedUserIds = createSelector(
  selectUserState,
  (state: UserState) => state.selectedMapUserIds
);
