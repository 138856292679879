import { createReducer, on } from '@ngrx/store';

import { StateUtils } from '@state/state-utils';
import * as InspectionImageTypeActions from './inspection-image-type.actions';
import {
  InspectionImageTypeState,
  initialInspectionImageTypeState,
} from './inspection-image-type.state';

const inspectionImageTypeReducer = createReducer<InspectionImageTypeState>(
  initialInspectionImageTypeState,

  on(
    InspectionImageTypeActions.createInspectionImageTypeSuccess,
    (state, { inspectionImageType }) => ({
      ...state,
      inspectionImageTypes: StateUtils.combineState(
        state.inspectionImageTypes,
        inspectionImageType
      ),
    })
  ),
  on(
    InspectionImageTypeActions.fetchInspectionImageTypesSuccess,
    (state, { inspectionImageTypes }) => ({
      ...state,
      inspectionImageTypes,
      fetchedAt: new Date(),
    })
  ),
  on(
    InspectionImageTypeActions.fetchInspectionImageTypeByIdSuccess,
    (state, { inspectionImageType }) => ({
      ...state,
      inspectionImageTypes: StateUtils.combineState(
        state.inspectionImageTypes,
        inspectionImageType
      ),
      fetchedAt: new Date(),
    })
  ),
  on(
    InspectionImageTypeActions.updateInspectionImageTypeSuccess,
    (state, { inspectionImageType }) => ({
      ...state,
      inspectionImageTypes: StateUtils.combineState(
        state.inspectionImageTypes,
        inspectionImageType
      ),
    })
  ),
  on(
    InspectionImageTypeActions.deleteInspectionImageTypeSuccess,
    (state, { id }) => ({
      ...state,
      inspectionImageTypes: state.inspectionImageTypes.filter(
        (x) => x.id !== id
      ),
    })
  ),
  on(
    InspectionImageTypeActions.fetchInspectionImageTypesFailed,
    InspectionImageTypeActions.fetchInspectionImageTypeByIdFailed,
    (state, { error }) => ({
      ...state,
      fetchedAt: new Date(),
    })
  )
);

export { inspectionImageTypeReducer };
