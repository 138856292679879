import { createAction, props } from '@ngrx/store';

import { Inspection } from '@models';


// Fetch Inspections:
export const fetchInspections = createAction(
  '[Inspection] Fetch Initiated'
);
export const fetchInspectionsSuccess = createAction(
  '[Inspection] Fetch Success',
  props<{ inspections: Inspection[] }>()
);
export const fetchInspectionsFailed = createAction(
  '[Inspection] Fetch Failed',
  props<{ error: Error }>()
);

// Fetch Inspection By Id:
export const fetchInspectionById = createAction(
  '[Inspection] Fetch By Id Initiated',
  props<{ id: string }>()
);
export const fetchInspectionByIdSuccess = createAction(
  '[Inspection] Fetch By Id  Success',
  props<{ inspection: Inspection | null }>()
);
export const fetchInspectionByIdFailed = createAction(
  '[Inspection] Fetch By Id  Failed',
  props<{ error: Error }>()
);

// Fetch Current User Inspections:
export const fetchCurrentUserInspections = createAction(
  '[Inspection] Fetch By Current User Initiated'
);

