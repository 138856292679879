import { Injectable } from '@angular/core';

import { Inspection } from '@models';
import { CrudService, HttpService } from '@services';

@Injectable({ providedIn: 'root' })
export class InspectionService extends CrudService<Inspection> {
  constructor(http: HttpService) {
    super('inspection', http);
  }

  async getUserInspections(userId: string): Promise<Inspection[]> {
    const response = await this.http.get<Inspection[]>(
      `${this.url}/user/${userId}`
    );
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  override create(data: Partial<Inspection>): Promise<Inspection> {
    throw new Error('Method not available.');
  }
  override update(data: Partial<Inspection>): Promise<Inspection> {
    throw new Error('Method not available.');
  }
  override delete(id: string): Promise<string> {
    throw new Error('Method not available.');
  }
}
