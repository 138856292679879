@if (documents.length) {
<div class="d-flex justify-content-between align-items-center mt-2">
  <div>
    <b>{{ docType.name }}</b>
  </div>
  <div class="d-flex justify-content-center2">
    {{ documentIndex + 1 + "/" + documents.length }}
  </div>
</div>

<div class="d-flex justify-content-end mt-2">
  <mat-icon>{{ getStatusIcon() }}</mat-icon>
  <div class="ms-2">{{ documentStatus() }}</div>
</div>

<div class="d-flex justify-content-center align-items-center my-3">
  <button mat-icon-button class="me-3" (click)="previousDocument()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div class="document-holder">
    <img [src]="documentUrl" />
  </div>
  <button mat-icon-button class="ms-3" (click)="nextDocument()">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div class="row mx-5">
  <div class="col-6">
    <display-input title="Document Option" [value]="doc.documentOption" />
  </div>
  <div class="col-6">
    <display-input title="ID Number" [value]="doc.idNumber" />
  </div>
  <div class="col-6">
    <display-input title="Expiry Date" value="{{ doc.expiryDate | date }}" />
  </div>
  <div class="col-6">
    <display-input title="Country Of Issue" [value]="doc.countryOfIssue" />
  </div>
</div>

@if (!loading) {
<div class="d-flex justify-content-end mt-3">
  <button
    (click)="onDeclineDocumentClicked()"
    [disabled]="doc.status == 'declined'"
    mat-raised-button
    color="warn"
    class="me-3 px-4"
  >
    Decline
  </button>
  <button
    (click)="onApproveDocumentClicked()"
    [disabled]="doc.status == 'approved'"
    mat-raised-button
    class="px-4"
  >
    Approve
  </button>
</div>
} @if (loading) {
<div class="d-flex justify-content-end mt-4">
  <mat-progress-spinner mode="indeterminate" color="primary" diameter="30" />
</div>
} } @if (!documents.length) {
<div class="d-flex justify-content-center align-items-center h-100">
  <mat-icon class="me-2">upload_file</mat-icon>
  <div>This user has not uploaded any documents</div>
</div>
}
