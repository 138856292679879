import { Trip } from '@models';

export interface TripState {
  trips: Trip[];
  fetchedAt: Date | null;
}

export const initialTripState: TripState = {
  trips: [],
  fetchedAt: null,
};
