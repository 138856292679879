import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { fetchExpenses, selectExpenses, selectExpensesFetchedAt } from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './expenses.page.html',
  styleUrl: './expenses.page.scss',
})
export class ExpensesPage implements OnInit {
  columns: string[] = ['name', 'description', 'value'];

  $expensesFetchedAt = this.store.select(selectExpensesFetchedAt);
  $expenses = this.store.select(selectExpenses);

  constructor(private store: Store, private router: Router) {}

  public ngOnInit(): void {
    this.store.dispatch(fetchExpenses());
  }

  public onAddExpenseClicked(): void {
    this.router.navigate([`/expenses/new`]);
  }

  public onRowClicked(id: string): void {
    this.router.navigate([`/expenses/${id}`]);
  }
}
