@if (address) {
<div class="d-flex justify-content-end mt-2 mb-4">
  Status:
  <div class="ms-2 me-2">{{ getStatus() }}</div>
  <mat-icon>{{ getStatusIcon() }}</mat-icon>
</div>

<display-input title="Street Number" [value]="address.streetNumber" />
<display-input title="Street" [value]="address.street" />
<display-input title="Suburb" [value]="address.suburb" />
<display-input title="Province" [value]="address.province" />
<display-input title="Postal Code" [value]="address.postalCode" />
<display-input title="Country" [value]="address.country" />

<div class="d-flex align-items-center justify-content-end mt-3">
  @if (loading) {
  <mat-progress-spinner mode="indeterminate" color="primary" diameter="30" />
  } @else {
  <div class="d-flex align-items-center">
    <button
      (click)="onDeclineClicked()"
      [disabled]="address.status == 'declined'"
      mat-raised-button
      color="warn"
      class="me-3 px-4"
    >
      Decline
    </button>

    <button
      (click)="onApproveClicked()"
      [disabled]="address.status == 'approved'"
      mat-raised-button
      class="px-4"
    >
      Approve
    </button>
  </div>
  }
</div>
} @if (!address) {
<div class="d-flex justify-content-center align-items-center h-100">
  <mat-icon class="me-2">home</mat-icon>
  <div>This user has not completed their address</div>
</div>
}
