import { Injectable } from '@angular/core';

import { InspectionImageType } from '@models';
import { CrudService, HttpService } from '@services';

@Injectable({ providedIn: 'root' })
export class InspectionImageTypeService extends CrudService<InspectionImageType> {
  constructor(http: HttpService) {
    super('inspection-image-type', http);
  }
}
