import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DateRange } from '@enums';
import { Trip, User } from '@models';
import { Store } from '@ngrx/store';
import { fetchTripsByUserId, selectTrips, selectTripsFetchedAt } from '@state';

@Component({
  selector: 'app-trips',
  standalone: true,
  imports: [
    MatTableModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    CommonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './trips.component.html',
  styleUrl: './trips.component.scss',
})
export class TripsComponent implements OnInit {
  @Input() user: User | null = null;

  public dataSource = new MatTableDataSource<Trip>();
  public dateRanges: string[] = Object.values(DateRange);
  public selectedDateRange: DateRange = DateRange.LAST_7_DAYS;
  public columns: string[] = [
    'date',
    'start',
    'end',
    'distance',
    'earnings',
    'tip',
  ];

  public $trips = this.store.select(selectTrips);
  public $tripsFetchedAt = this.store.select(selectTripsFetchedAt);

  constructor(private store: Store) {}

  ngOnInit() {
    if (this.user) this.fetchDriverTrips(this.user, DateRange.LAST_7_DAYS);
    this.$trips.subscribe((trips) => {
      this.dataSource.data = trips;
    });
  }

  private fetchDriverTrips(user: User, dateRange: DateRange): void {
    if (user) {
      this.store.dispatch(
        fetchTripsByUserId({
          userId: user.id!,
          dateRange: dateRange,
        })
      );
    }
  }

  formatDateTime(date: Date) {
    return new Date(date).toLocaleString().split(',')[0];
  }

  filterByDateRange() {
    if (this.user && this.selectedDateRange) {
      this.fetchDriverTrips(this.user, this.selectedDateRange);
    }
  }

  navToTrip(trip: Trip) {
    console.log('navigating to trip', trip);
  }
}
