import { createReducer, on } from '@ngrx/store';

import { StateUtils } from '@state/state-utils';
import * as IncidentActions from './incident.actions';
import { IncidentState, initialIncidentState } from './incident.state';

const incidentReducer = createReducer<IncidentState>(
  initialIncidentState,

  on(IncidentActions.fetchIncidentsSuccess, (state, { incidents }) => ({
    ...state,
    incidents,
    fetchedAt: new Date(),
  })),
  on(IncidentActions.fetchIncidentsFailed, (state, { error }) => ({
    ...state,
    fetchedAt: new Date(),
  })),

  on(IncidentActions.fetchIncidentByIdSuccess, (state, { incident }) => ({
    ...state,
    incidents: StateUtils.combineState(state.incidents, incident),
    fetchedAt: new Date(),
  })),
  on(IncidentActions.fetchIncidentByIdFailed, (state, { error }) => ({
    ...state,
    fetchedAt: new Date(),
  }))
);

export { incidentReducer };
