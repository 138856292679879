import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Database, onValue, ref } from '@angular/fire/database';
import {
  GoogleMapsModule,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
import { Store } from '@ngrx/store';
import { selectMapSelectedUserIds, setSelectedMapUsers } from '@state';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule],
  templateUrl: './map.page.html',
  styleUrl: './map.page.scss',
})
export class MapPage implements OnInit, AfterViewInit {
  @ViewChildren(MapInfoWindow) infoWindowsView:
    | QueryList<MapInfoWindow>
    | undefined;
  @ViewChildren(MapMarker) mapMarkers!: QueryList<MapMarker>;
  private database: Database = inject(Database);
  private markerMap = new Map<string, MapMarker>();
  private $selectedMapUsers = this.store.select(selectMapSelectedUserIds);

  public center = { lat: -26.2041, lng: 28.0473 }; // Center on Johannesburg
  public zoom = 5;
  public drivers: {
    id: string;
    location: { lat: number; lng: number };
    name: string;
  }[] = [];
  public selectedDriverIds: string[] = [];
  public motorbikeIcon = {
    url: 'bike_icon.png',
    scaledSize: { width: 32, height: 32, equals: () => false },
  };

  constructor(private store: Store) {
    const dbRef = ref(this.database, 'drivers');
    onValue(dbRef, (snapshot) => {
      const updatedDrivers: any[] = [];
      snapshot.forEach((child) => {
        const driver = child.val();
        updatedDrivers.push({
          id: child.key,
          location: {
            lat: driver.location.latitude,
            lng: driver.location.longitude,
          },
          name: driver?.firstName + driver?.lastName || '--', // Replace with actual driver name
        });
      });
      this.drivers = updatedDrivers;
      setTimeout(() => {
        this.openSelectedInfoWindows();
      });
    });
  }

  ngOnInit() {
    this.$selectedMapUsers.subscribe((selectedMapUsers) => {
      if (selectedMapUsers) {
        this.selectedDriverIds = selectedMapUsers;
        this.openSelectedInfoWindows();
      }
    });
  }

  ngAfterViewInit() {
    this.mapMarkers.changes.subscribe(() => {
      this.registerMarkers();
      this.openSelectedInfoWindows();
    });
  }

  private registerMarkers() {
    this.mapMarkers.forEach((marker) => {
      const driverId = marker.getTitle();
      if (driverId) {
        this.markerMap.set(driverId, marker);
      }
    });
  }

  private openSelectedInfoWindows() {
    this.selectedDriverIds.forEach((driverId) => {
      const marker = this.getMarkerByDriverId(driverId);
      const infoWindow = this.getInfoWindowByDriverId(driverId);
      if (marker && infoWindow) {
        infoWindow.open(marker);
      }
    });
  }

  private getMarkerByDriverId(driverId: string): MapMarker | undefined {
    return this.markerMap.get(driverId);
  }

  private getInfoWindowByDriverId(driverId: string): MapInfoWindow | undefined {
    const index = this.selectedDriverIds.indexOf(driverId);
    return this.infoWindowsView?.toArray()[index];
  }

  public onMarkerClick(driverId: string) {
    this.selectedDriverIds = [...this.selectedDriverIds, driverId];
    this.store.dispatch(setSelectedMapUsers({ users: this.selectedDriverIds }));
  }

  public onInfoWindowClose(driverId: string) {
    this.selectedDriverIds = this.selectedDriverIds.filter(
      (_driverId) => _driverId !== driverId
    );
    this.store.dispatch(setSelectedMapUsers({ users: this.selectedDriverIds }));
  }

  public getDriverLocation(driverId: string): google.maps.LatLngLiteral {
    const driver = this.drivers.find((d) => d.id === driverId);
    if (!driver) return { lat: 0, lng: 0 };
    driver.location.lat = Math.round(driver.location.lat * 100000) / 100000;
    driver.location.lng = Math.round(driver.location.lng * 100000) / 100000;
    return driver.location;
  }

  public getDriverName(driverId: string): string {
    const driver = this.drivers.find((d) => d.id === driverId);
    return driver ? driver.name : '';
  }
}
