import { Inspection } from '@models';

export interface InspectionState {
  inspections: Inspection[];
  fetchedAt: Date | null;
}

export const initialInspectionState: InspectionState = {
  inspections: [],
  fetchedAt: null,
};
