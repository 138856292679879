import { createAction, props } from '@ngrx/store';

import { Incident } from '@models';

export const fetchIncidents = createAction('[User] Fetch Incidents Initiated');
export const fetchIncidentsSuccess = createAction(
  '[User] Fetch Incidents Success',
  props<{ incidents: Incident[] }>()
);
export const fetchIncidentsFailed = createAction(
  '[User] Fetch Incidents Failed',
  props<{ error: Error }>()
);

// fetch incident by id
export const fetchIncidentById = createAction(
  '[User] Fetch Incident By Id Initiated',
  props<{ incidentId: string }>()
);
export const fetchCurrentIncident = createAction(
  '[User] Fetch Current Incident Initiated'
);
export const fetchIncidentByIdSuccess = createAction(
  '[User] Fetch Incident By Id Success',
  props<{ incident: Incident }>()
);
export const fetchIncidentByIdFailed = createAction(
  '[User] Fetch User By Id Failed',
  props<{ error: Error }>()
);
