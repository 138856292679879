import { Vehicle, VehicleEvent } from '@models';

export interface VehicleState {
  vehicles: Vehicle[];
  fetchedAt: Date | null;
  vehicleEvents: VehicleEvent[];
  eventsFetchedAt: Date | null;
}

export const initialVehicleState: VehicleState = {
  vehicles: [],
  fetchedAt: null,
  vehicleEvents: [],
  eventsFetchedAt: null,
};
