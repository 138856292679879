import { BaseModel } from './base.model';

export class InspectionImageType extends BaseModel {
  name: string = '';
  description: string = '';

  constructor(data?: Partial<InspectionImageType>) {
    super();
    Object.assign(this, data);
  }
}
