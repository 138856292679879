import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DisplayInputComponent } from '@components';

import { Status } from '@enums';
import { Profile, User } from '@models';
import { UtilService } from '@services';
import * as UserActions from '@state/user/user.actions';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    DisplayInputComponent,
    MatProgressSpinnerModule,
    MatGridListModule,
  ],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
})
export class UserProfileComponent implements OnChanges {
  @Input() user: User | null = null;
  @Output() createCarTrackProfile: EventEmitter<void> =
    new EventEmitter<void>();

  profile: Profile | undefined;

  public loading: boolean = false;
  public error: string = '';

  constructor(private utils: UtilService) {}

  async ngOnChanges(): Promise<void> {
    if (this.user && this.user.profile) {
      this.profile = this.user.profile;
    }
  }

  public async onApproveClicked() {
    this.saveProfileStatus(Status.APPROVED);
  }

  public async onDeclineClicked() {
    this.saveProfileStatus(Status.DECLINED);
  }

  private async saveProfileStatus(status: Status) {
    this.error = '';
    this.loading = true;

    try {
      const profile = this.profile!;
      await this.utils.actionsToPromise(
        UserActions.setUserProfileStatus({ profile, status }),
        UserActions.setUserProfileStatusSuccess,
        UserActions.setUserProfileStatusFailed
      );
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }

  public onCreateCarTrackProfile() {
    this.error = '';
    this.loading = true;

    try {
      this.createCarTrackProfile.emit();
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }

  public getStatus(): string {
    if (this.profile?.status == Status.APPROVED) return 'Approved';
    if (this.profile?.status == Status.DECLINED) return 'Declined';
    return 'Pending';
  }

  public getStatusIcon(): string {
    if (this.profile?.status == Status.APPROVED) return 'check_circle';
    if (this.profile?.status == Status.DECLINED) return 'cancel';
    return 'pending';
  }
}
