@let incident = $incident | async;

<mat-card>
  <mat-card-content>
    @if (incident) {
    <div class="flex">
      <div class="left">
        <div>
          <div
            (click)="setTab('details')"
            [class.active]="currentTab === 'details'"
            class="tab"
          >
            Incident Details
          </div>
          <div
            (click)="setTab('scene-images')"
            [class.active]="currentTab === 'scene-images'"
            class="tab"
          >
            Scene Images
          </div>
          <div
            (click)="setTab('vehicle-images')"
            [class.active]="currentTab === 'vehicle-images'"
            class="tab"
          >
            Vehicle Images
          </div>
          <div
            (click)="setTab('supporting-images')"
            [class.active]="currentTab === 'supporting-images'"
            class="tab"
          >
            Supporting Documents
          </div>
        </div>
      </div>

      @if (currentTab == 'details') {
      <div class="right">
        <app-incident-summary [incident]="incident" />
      </div>
      } @if (currentTab == 'scene-images') {
      <div class="right">
        <app-incident-images
          [images]="returnImageByType(incident, 'scene')"
          [imageType]="'Scene Images'"
        />
      </div>
      } @if (currentTab == 'vehicle-images') {
      <div class="right">
        <app-incident-images
          [images]="returnImageByType(incident, 'vehicle')"
          [imageType]="'Vehicle Images'"
        />
      </div>
      } @if (currentTab == 'supporting-images') {
      <div class="right">
        <app-incident-images
          [images]="returnImageByType(incident, 'supportingDocument')"
          [imageType]="'Supporting Documents'"
        />
      </div>
      }
    </div>
    } @if (!($incidentsFetched | async)) {
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    }
  </mat-card-content>
</mat-card>
