import { Injectable } from '@angular/core';

import { Status } from '@enums';
import { DocumentType, User } from '@models';
import { CrudService, HttpService } from '@services';
import { environment } from '../../../environment/environment';

@Injectable({ providedIn: 'root' })
export class UserService extends CrudService<User> {
  constructor(http: HttpService) {
    super('user', http);
  }

  public static isUserApproved(
    user: User,
    documentTypes: DocumentType[]
  ): boolean {
    return (
      this.isUserProfileApproved(user) &&
      this.isUserAddressApproved(user) &&
      this.areUserDocumentsApproved(user, documentTypes) &&
      !!user?.carTrackDriverId &&
      !!user?.vehicle
    );
  }

  public static isUserElegibleForVehicle(
    user: User,
    documentTypes: DocumentType[]
  ): boolean {
    return (
      !user.vehicle &&
      this.isUserProfileApproved(user) &&
      this.isUserAddressApproved(user) &&
      this.areUserDocumentsApproved(user, documentTypes) &&
      !!user?.carTrackDriverId
    );
  }

  public static isUserProfileApproved(user: User): boolean {
    return user.profile?.status === Status.APPROVED;
  }

  public static isUserAddressApproved(user: User): boolean {
    return user.address?.status === Status.APPROVED;
  }

  public static areUserDocumentsApproved(
    user: User,
    documentTypes: DocumentType[]
  ): boolean {
    const documents = user.documents;
    if (!documents || !documents.length) return false;

    const requiredDocTypes = documentTypes.filter((x) => x.required);
    for (const docType of requiredDocTypes) {
      const doc = documents.find((x) => x.documentType.id === docType.id);
      if (!doc || doc.status != Status.APPROVED) return false;
    }

    return true;
  }

  public async createCarTrackProfile(user: User): Promise<any> {
    const endpoint = `${environment.apiUrl}/admin/car-track/profile`;
    const response = await this.http.post(endpoint, {
      userId: user?.id,
    });
    if (!response.success) throw new Error(response.message);
    return response.data;
  }
}
