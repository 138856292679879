import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ActionType, User } from '@models';
import {
  fetchActions,
  fetchUsers,
  selectActions,
  selectActionsFetchedAt,
  selectUsers,
} from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './actions.page.html',
  styleUrl: './actions.page.scss',
})
export class ActionsPage implements OnInit, OnDestroy {
  columns: string[] = ['date', 'user', 'performedBy', 'action', 'document'];

  $actions = this.store.select(selectActions);
  $actionsFetchedAt = this.store.select(selectActionsFetchedAt);
  private $users = this.store.select(selectUsers);
  private users: User[] = [];
  private sub: Subscription | null = null;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.store.dispatch(fetchActions());
    this.store.dispatch(fetchUsers());

    this.sub = this.$users.subscribe((users) => (this.users = users));
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  public getActionName(action: ActionType): string {
    if (action === ActionType.LOGIN) return 'Login';
    if (action === ActionType.APPROVE_DOCUMENT) return 'Approve Document';
    if (action === ActionType.DECLINE_DOCUMENT) return 'Decline Document';
    if (action === ActionType.ASSIGN_VEHICLE) return 'Assign Vehicle';

    return '';
  }

  public getUserDisplay(user: User): string {
    if (!user) return 'Unknown User';
    if (user.email) return user.email;
    const u = this.users.find((u) => u.id === user.id);
    if (!u) return user.mobile;
    return u.profile?.firstName + ' ' + u.profile?.lastName;
  }
}
