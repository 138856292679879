import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { CarTrackEventType, DateRange } from '@enums';
import { User, VehicleEvent } from '@models';
import { Store } from '@ngrx/store';
import {
  fetchVehicleEvents,
  selectVehicleEvents,
  selectVehicleEventsFetchedAt,
} from '@state';
import { DriverMetricsUtils } from '@utils';
import { Chart } from 'chart.js';
import { CountUpDirective } from 'src/app/directives';

@Component({
  selector: 'app-driver-behavior',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatGridListModule,
    MatListModule,
    CountUpDirective,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './driver-behavior.component.html',
  styleUrl: './driver-behavior.component.scss',
})
export class DriverBehaviorComponent implements OnInit {
  @Input() public user: User | null = null;

  @ViewChild('radarChart')
  private radarChartRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('lineChart') private lineChartRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('score') private score!: ElementRef<HTMLCanvasElement>;
  private radarChart!: Chart<'radar'>;
  private lineChart!: Chart<'line'>;

  public dateRanges: string[] = Object.values(DateRange);
  public selectedDateRange: DateRange = DateRange.LAST_7_DAYS;
  public driverScore: number = 0;

  public vehicleEvents: VehicleEvent[] = [];
  public $vehicleEvents = this.store.select(selectVehicleEvents);
  public $vehicleEventsFetchedAt = this.store.select(
    selectVehicleEventsFetchedAt
  );

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (this.user) this.onFetchVehicleEvents(this.user, this.selectedDateRange);
    this.$vehicleEvents.subscribe((events) => {
      this.vehicleEvents = events;
      setTimeout(() => {
        this.radarChart?.destroy();
        this.lineChart?.destroy();
        this.createRadarChart();
        this.createLineChart();
      });
    });
  }

  private onFetchVehicleEvents(user: User, dateRange: DateRange): void {
    if (user) {
      this.store.dispatch(
        fetchVehicleEvents({
          carTrackDriverId: user?.carTrackDriverId ?? '',
          dateRange: dateRange,
        })
      );
    }
  }

  private createRadarChart(): void {
    if (!this.vehicleEvents?.length) return;
    const data = DriverMetricsUtils.buildRadarChatData(this.vehicleEvents);
    const { driverScore, minValue, maxValue } =
      DriverMetricsUtils.calculateDriverBehaviorMetrics(data);
    this.driverScore = driverScore;
    setTimeout(() => {
      this.score.nativeElement.style.backgroundColor = this.driverScoreColor;
    });
    const dataset = {
      label: 'Driver Warnings',
      data: data.map((item) => item.count),
      fill: true,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      // borderColor: this.driverScoreColor,
      pointBackgroundColor: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        return DriverMetricsUtils.getColorForValue(value, minValue, 30);
      },
      pointBorderColor: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        return DriverMetricsUtils.getColorForValue(value, minValue, 30);
      },
      pointBorderWidth: 1,
      pointRadius: 3,
      pointHoverRadius: 5,
    };

    this.radarChart = new Chart(this.radarChartRef.nativeElement, {
      type: 'radar',
      data: {
        labels: data.map((item) => item.label),
        datasets: [dataset],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            min: minValue,
            max: maxValue,
            reverse: true,
          },
        },
        elements: {
          line: {
            borderWidth: 2,
          },
        },
      },
    });
  }

  private createLineChart(): void {
    if (!this.vehicleEvents?.length) return;
    const data = DriverMetricsUtils.buildLineChartData(this.vehicleEvents);
    const minValue = 0;
    const maxValue = 30;
    const dataset = {
      label: 'Driver Warnings',
      data: data.map((item) => item.count),
      fill: true,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      // borderColor: this.driverScoreColor,
      pointBackgroundColor: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        return DriverMetricsUtils.getColorForValue(value, minValue, maxValue);
      },
      pointBorderColor: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        return DriverMetricsUtils.getColorForValue(value, minValue, maxValue);
      },
      pointBorderWidth: 1,
      pointRadius: 3,
      pointHoverRadius: 5,
    };
    this.lineChart = new Chart(this.lineChartRef.nativeElement, {
      type: 'line',
      data: {
        labels: data.map((item) => item.label),
        datasets: [dataset],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          line: {
            borderWidth: 2,
          },
        },
      },
    });
  }

  public filterByDateRange(): void {
    if (this.user) {
      this.onFetchVehicleEvents(this.user, this.selectedDateRange);
    }
  }

  get driverScoreColor(): string {
    return DriverMetricsUtils.getColorForValue(100 - this.driverScore, 0, 100);
  }

  public get collisions(): number {
    return (
      this.vehicleEvents?.filter(
        (event) =>
          event.eventDescription === CarTrackEventType.FORWARD_COLLISION_WARNING
      ).length || 0
    );
  }

  public get shockWarnings(): number {
    return (
      this.vehicleEvents?.filter(
        (event) => event.eventDescription === CarTrackEventType.SHOCK
      ).length || 0
    );
  }

  public get harshBrakingWarnings(): number {
    return (
      this.vehicleEvents?.filter(
        (event) => event.eventDescription === CarTrackEventType.HARSH_BRAKING
      ).length || 0
    );
  }

  public get harshAccelerationWarnings(): number {
    return (
      this.vehicleEvents?.filter(
        (event) =>
          event.eventDescription === CarTrackEventType.HARSH_ACCELERATION
      ).length || 0
    );
  }

  public get harshCorneringWarnings(): number {
    return (
      this.vehicleEvents?.filter(
        (event) => event.eventDescription === CarTrackEventType.HARSH_CORNERING
      ).length || 0
    );
  }

  public get speedingWarnings(): number {
    return (
      this.vehicleEvents?.filter(
        (event) => event.eventDescription === CarTrackEventType.SPEEDING_START
      ).length || 0
    );
  }
}
