import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';

import { AlertComponent, DisplayInputComponent } from '@components';
import { Vehicle } from '@models';
import { UtilService } from '@services';
import {
  assignUserToVehicle,
  assignUserToVehicleFailed,
  assignUserToVehicleSuccess,
  fetchCurrentVehicle,
  fetchUsers,
  removeUserFromVehicle,
  removeUserFromVehicleFailed,
  removeUserFromVehicleSuccess,
  selectCurrentVehicle,
  selectUsersElegibleForVehicle,
  selectVehiclesFetchedAt,
} from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    DisplayInputComponent,
    AlertComponent,
  ],
  templateUrl: './vehicle-details.page.html',
  styleUrl: './vehicle-details.page.scss',
})
export class VehicleDetailsPage implements OnInit {
  $vehicle = this.store.select(selectCurrentVehicle);
  $users = this.store.select(selectUsersElegibleForVehicle);
  $vehiclesFetchedAt = this.store.select(selectVehiclesFetchedAt);

  userId = '';
  loading: boolean = false;
  error: string = '';

  constructor(private store: Store, private utils: UtilService) {}

  public ngOnInit(): void {
    this.store.dispatch(fetchCurrentVehicle());
    this.store.dispatch(fetchUsers());
  }

  public async onAssignDriverClicked(vehicleId: string, userId: string) {
    if (!userId || !vehicleId) {
      this.error = 'Please select a driver to assign to the vehicle.';
      return;
    }

    this.error = '';
    this.loading = true;
    try {
      await this.utils.actionsToPromise(
        assignUserToVehicle({ vehicleId, userId }),
        assignUserToVehicleSuccess,
        assignUserToVehicleFailed
      );
    } catch (err: any) {
      this.error = err.message;
    }
    this.loading = false;
  }

  public async onRemoveDriverClicked(vehicle: Vehicle) {
    if (!vehicle.id || !vehicle?.user?.id) {
      this.error = 'Cannot remove driver from vehicle.';
      return;
    }

    this.error = '';
    this.loading = true;
    try {
      const payload = { vehicleId: vehicle.id, userId: vehicle.user.id };

      await this.utils.actionsToPromise(
        removeUserFromVehicle(payload),
        removeUserFromVehicleSuccess,
        removeUserFromVehicleFailed
      );
    } catch (err: any) {
      this.error = err.message;
    }
    this.loading = false;
  }
}
