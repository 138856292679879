import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { DisplayInputComponent } from '@components';
import { User, Vehicle } from '@models';

@Component({
  selector: 'app-assigned-vehicle',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    DisplayInputComponent,
    MatButtonModule,
  ],
  templateUrl: './assigned-vehicle.component.html',
  styleUrl: './assigned-vehicle.component.scss',
})
export class AssignedVehicleComponent {
  @Input() user: User | null = null;
  @Output() setTab: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router) {}

  public get carTrackDriverId() {
    return this.user?.carTrackDriverId;
  }

  public get vehicle(): Vehicle | undefined {
    return this.user?.vehicle;
  }

  public onCreateDriverProfileClicked(): void {
    this.setTab.emit('profile');
  }

  public onAssignDriverClicked(): void {
    this.router.navigate(['vehicles']);
  }
}
