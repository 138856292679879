import { createAction, props } from '@ngrx/store';

import { Status } from '@enums';
import { Address, Document, Profile, User } from '@models';

export const fetchUsers = createAction('[User] Fetch Users Initiated');
export const fetchUsersSuccess = createAction(
  '[User] Fetch Users Success',
  props<{ users: User[] }>()
);
export const fetchUsersFailed = createAction(
  '[User] Fetch Users Failed',
  props<{ error: Error }>()
);

// fetch user by id
export const fetchUserById = createAction(
  '[User] Fetch User By Id Initiated',
  props<{ userId: string }>()
);
export const fetchCurrentUser = createAction(
  '[User] Fetch Current User Initiated'
);
export const fetchUserByIdSuccess = createAction(
  '[User] Fetch User By Id Success',
  props<{ user: User }>()
);
export const fetchUserByIdFailed = createAction(
  '[User] Fetch User By Id Failed',
  props<{ error: Error }>()
);

// update user
export const updateUser = createAction(
  '[User] Update user',
  props<{ user: User }>()
);
export const updateUserSuccess = createAction(
  '[User] Update User Success',
  props<{ user: User }>()
);
export const updateUserFailed = createAction(
  '[User] Update User Failed',
  props<{ error: Error }>()
);

// set user profile status
export const setUserProfileStatus = createAction(
  '[User] Set User Profile Status',
  props<{ profile: Profile; status: Status }>()
);
export const setUserProfileStatusSuccess = createAction(
  '[User] Set User Profile Status Success',
  props<{ profile: Profile }>()
);
export const setUserProfileStatusFailed = createAction(
  '[User] Set User Profile Status Failed',
  props<{ error: Error }>()
);

// set user address status
export const setUserAddressStatus = createAction(
  '[User] Set User Address Status',
  props<{ address: Address; status: Status }>()
);
export const setUserAddressStatusSuccess = createAction(
  '[User] Set User Address Status Success',
  props<{ address: Address }>()
);
export const setUserAddressStatusFailed = createAction(
  '[User] Set User Address Status Failed',
  props<{ error: Error }>()
);

// set user document status
export const setUserDocumentStatus = createAction(
  '[User] Set User Document Status',
  props<{ document: Document; status: Status }>()
);
export const setUserDocumentStatusSuccess = createAction(
  '[User] Set User Document Success',
  props<{ document: Document }>()
);
export const setUserDocumentStatusFailed = createAction(
  '[User] Set User Document Failed',
  props<{ error: Error }>()
);

// set map selected users
export const setSelectedMapUsers = createAction(
  '[User] Set Selected Map Users',
  props<{ users: any[] }>()
);

// Create CarTrack Profile
export const createCarTrackProfile = createAction(
  '[User] Create CarTrack Profile',
  props<{ user: User }>()
);
export const createCarTrackProfileSuccess = createAction(
  '[User] Create CarTrack Profile Success',
  props<{ user: User }>()
);
export const createCarTrackProfileFailed = createAction(
  '[User] Create CarTrack Profile Failed',
  props<{ error: Error }>()
);
