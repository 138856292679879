import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { InspectionImageTypeService } from '@services';
import { selectRouteState } from '@state/route';
import * as InspectionImageTypeActions from './inspection-image-type.actions';

@Injectable()
export class InspectionImageTypeEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private inspectionImageTypeService: InspectionImageTypeService
  ) {}

  // Create
  createInspectionImageType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionImageTypeActions.createInspectionImageType),
      switchMap(({ inspectionImageType }) =>
        from(this.inspectionImageTypeService.create(inspectionImageType)).pipe(
          map((inspectionImageType) =>
            InspectionImageTypeActions.createInspectionImageTypeSuccess({ inspectionImageType })
          ),
          catchError((error) =>
            of(InspectionImageTypeActions.createInspectionImageTypeFailed({ error }))
          )
        )
      )
    )
  );

  //Read
  fetchInspectionImageTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionImageTypeActions.fetchInspectionImageTypes),
      switchMap(() =>
        from(this.inspectionImageTypeService.get()).pipe(
          map((inspectionImageTypes) =>
            InspectionImageTypeActions.fetchInspectionImageTypesSuccess({
              inspectionImageTypes: inspectionImageTypes,
            })
          ),
          catchError((error) =>
            of(InspectionImageTypeActions.fetchInspectionImageTypesFailed({ error }))
          )
        )
      )
    )
  );

  fetchInspectionImageType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionImageTypeActions.fetchInspectionImageTypeById),
      switchMap(({ id }) =>
        from(this.inspectionImageTypeService.getById(id)).pipe(
          map((inspectionImageType) =>
            InspectionImageTypeActions.fetchInspectionImageTypeByIdSuccess({ inspectionImageType })
          ),
          catchError((error) =>
            of(InspectionImageTypeActions.fetchInspectionImageTypeByIdFailed({ error }))
          )
        )
      )
    )
  );

  fetchCurrentInspectionImageType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionImageTypeActions.fetchCurrentInspectionImageType),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const inspectionImageTypeId = route.params['inspectionImageTypeId'];

        if (inspectionImageTypeId === 'new') {
          const inspectionImageType = null;
          return of(
            InspectionImageTypeActions.fetchInspectionImageTypeByIdSuccess({ inspectionImageType })
          );
        } else {
          return of(
            InspectionImageTypeActions.fetchInspectionImageTypeById({ id: inspectionImageTypeId })
          );
        }
      })
    )
  );

  // Update
  updateInspectionImageType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionImageTypeActions.updateInspectionImageType),
      switchMap(({ inspectionImageType }) =>
        from(this.inspectionImageTypeService.update(inspectionImageType)).pipe(
          map((inspectionImageType) =>
            InspectionImageTypeActions.updateInspectionImageTypeSuccess({ inspectionImageType })
          ),
          catchError((error) =>
            of(InspectionImageTypeActions.updateInspectionImageTypeFailed({ error }))
          )
        )
      )
    )
  );

  // Delete
  deleteInspectionImageType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionImageTypeActions.deleteInspectionImageType),
      switchMap(({ id }) =>
        from(this.inspectionImageTypeService.delete(id)).pipe(
          map(() => InspectionImageTypeActions.deleteInspectionImageTypeSuccess({ id })),
          catchError((error) =>
            of(InspectionImageTypeActions.deleteInspectionImageTypeFailed({ error }))
          )
        )
      )
    )
  );
}
