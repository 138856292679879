import { Incident } from '@models';

export interface IncidentState {
  incidents: Incident[];
  fetchedAt: Date | null;
}

export const initialIncidentState: IncidentState = {
  incidents: [],
  fetchedAt: null,
};
