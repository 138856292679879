import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AlertComponent, DialogComponent } from '@components';
import { InspectionImageType } from '@models';
import { UtilService } from '@services';
import {
  createInspectionImageType,
  createInspectionImageTypeFailed,
  createInspectionImageTypeSuccess,
  deleteInspectionImageType,
  deleteInspectionImageTypeFailed,
  deleteInspectionImageTypeSuccess,
  fetchCurrentInspectionImageType,
  selectCurrentInspectionImageType,
  selectInspectionImageTypesFetchedAt,
  updateInspectionImageType,
  updateInspectionImageTypeFailed,
  updateInspectionImageTypeSuccess,
} from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    AlertComponent,
  ],
  templateUrl: './inspection-image-type.page.html',
  styleUrl: './inspection-image-type.page.scss',
})
export class InspectionImageTypePage implements OnInit, OnDestroy {
  readonly dialog = inject(MatDialog);

  public loading: boolean = false;
  public error: string | null = null;
  public inspectionImageType: InspectionImageType = new InspectionImageType();
  private sub: Subscription | null = null;

  public $fetchedAt = this.store.select(selectInspectionImageTypesFetchedAt);
  private $imageType = this.store.select(selectCurrentInspectionImageType);

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });

  constructor(
    private store: Store,
    private router: Router,
    private utils: UtilService
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(fetchCurrentInspectionImageType());

    this.sub = this.$imageType.subscribe((imageType) => {
      if (!imageType) return;
      this.inspectionImageType = imageType;
      this.populateForm(imageType);
    });
  }

  private populateForm(imageType: InspectionImageType): void {
    this.form.patchValue({
      name: imageType.name,
      description: imageType.description,
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  public getFormControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  public async onFormSubmit(): Promise<void> {
    if (!this.form.valid) {
      this.error = 'Please fill in all required fields';
      return;
    }

    this.loading = true;

    try {
      const inspectionImageType: InspectionImageType = {
        ...this.inspectionImageType,
        name: this.getFormControl('name').value,
        description: this.getFormControl('description').value,
      };

      if (!this.inspectionImageType.id) {
        await this.createInspectionImageType(inspectionImageType);
      } else {
        await this.updateInspectionImageType(inspectionImageType);
      }

      this.dialog
        .open(DialogComponent, {
          data: {
            title: 'Success',
            message: 'Inspection Image saved successfully',
          },
        })
        .afterClosed()
        .subscribe(() => {
          this.router.navigate(['/inspection-image-types']);
        });
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }

  private async createInspectionImageType(
    inspectionImageType: InspectionImageType
  ): Promise<void> {
    await this.utils.actionsToPromise(
      createInspectionImageType({ inspectionImageType }),
      createInspectionImageTypeSuccess,
      createInspectionImageTypeFailed
    );
  }

  private async updateInspectionImageType(
    inspectionImageType: InspectionImageType
  ): Promise<void> {
    await this.utils.actionsToPromise(
      updateInspectionImageType({ inspectionImageType }),
      updateInspectionImageTypeSuccess,
      updateInspectionImageTypeFailed
    );
  }

  public onDeleteInspectionImageType(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete Inspection Image',
        message: 'Are you sure you want to delete this inspection image?',
        cancel: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.deleteInspectionImageType(this.inspectionImageType);
    });
  }

  private async deleteInspectionImageType(
    inspectionImageType: InspectionImageType
  ): Promise<void> {
    this.loading = true;

    try {
      await this.utils.actionsToPromise(
        deleteInspectionImageType({ id: inspectionImageType.id! }),
        deleteInspectionImageTypeSuccess,
        deleteInspectionImageTypeFailed
      );

      this.router.navigate(['/inspection-image-types']);
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }
}
