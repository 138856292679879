@let fetchedAt = $inspectionImageTypesFetchedAt | async; @let
inspectionImageTypes = $inspectionImageTypes | async;

<mat-card>
  <mat-card-header class="d-flex justify-content-between">
    <mat-card-title>Inspection Images</mat-card-title>
    <div>
      <button
        (click)="onAddInspectionImageTypeClicked()"
        mat-raised-button
        color="primary"
        type="submit"
        class="px-4"
      >
        Add Inspection Image Type
      </button>
    </div>
  </mat-card-header>

  <mat-card-content>
    <div class="mb-3 small">
      When diong an instection, a driver must upload an image matching each of
      the "Inspection Images" below.
    </div>

    @if (!fetchedAt) {
    <div class="d-flex justify-content-center mt-4">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    }
    <!----->
    @if (fetchedAt && inspectionImageTypes?.length) {
    <table mat-table [dataSource]="inspectionImageTypes ?? []">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.description }}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns;"
        (click)="onRowClicked(row.id)"
      ></tr>
    </table>
    }
    <!----->
    @if (fetchedAt && !inspectionImageTypes?.length) {
    <div class="text-center">
      <p>No inspection images</p>
    </div>
    }
  </mat-card-content>
</mat-card>
