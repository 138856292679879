import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DisplayInputComponent } from '@components';

import { Status } from '@enums';
import { Address, User } from '@models';
import { UtilService } from '@services';
import * as UserActions from '@state/user/user.actions';

@Component({
  selector: 'app-user-address',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    DisplayInputComponent,
    MatProgressSpinnerModule,
    MatGridListModule,
  ],
  templateUrl: './user-address.component.html',
  styleUrl: './user-address.component.scss',
})
export class UserAddressComponent implements OnChanges {
  @Input() user: User | null = null;

  address: Address | undefined;

  public loading: boolean = false;
  public error: string | null = '';

  constructor(private utils: UtilService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user && this.user.address) {
      this.address = this.user.address;
    }
  }

  public async onApproveClicked() {
    this.saveProfileStatus(Status.APPROVED);
  }

  public async onDeclineClicked() {
    this.saveProfileStatus(Status.DECLINED);
  }

  private async saveProfileStatus(status: Status) {
    this.error = '';
    this.loading = true;

    try {
      const address = this.address!;
      await this.utils.actionsToPromise(
        UserActions.setUserAddressStatus({ address, status }),
        UserActions.setUserAddressStatusSuccess,
        UserActions.setUserAddressStatusFailed
      );
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }

  public getStatus(): string {
    if (this.address?.status == Status.APPROVED) return 'Approved';
    if (this.address?.status == Status.DECLINED) return 'Declined';
    return 'Pending';
  }

  public getStatusIcon(): string {
    if (this.address?.status == Status.APPROVED) return 'check_circle';
    if (this.address?.status == Status.DECLINED) return 'cancel';
    return 'pending';
  }
}
