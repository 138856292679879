import { Injectable } from '@angular/core';

import { Status } from '@enums';
import { Address } from '@models';
import { CrudService, HttpService } from '@services';
import { environment } from '../../../environment/environment';

@Injectable({ providedIn: 'root' })
export class AddressService extends CrudService<Address> {
  constructor(http: HttpService) {
    super('address', http);
  }

  async setAddressStatus(address: Address, status: Status): Promise<Address> {
    const endpoint = `${environment.apiUrl}/address/status`;
    const response = await this.http.post<Address>(endpoint, {
      addressId: address.id,
      status,
    });

    if (!response.success) throw new Error(response.message);
    return response.data;
  }
}
