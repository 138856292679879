import { createReducer, on } from '@ngrx/store';

import * as TripActions from './trip.actions';
import { initialTripState, TripState } from './trip.state';

const tripReducer = createReducer<TripState>(
  initialTripState,
  on(TripActions.fetchTrips, (state) => ({
    ...state,
    fetchedAt: null,
  })),
  on(TripActions.fetchTripsByUserId, (state) => ({
    ...state,
    fetchedAt: null,
  })),
  on(TripActions.fetchTripsSuccess, (state, { trips }) => ({
    ...state,
    trips,
    fetchedAt: new Date(),
  })),
  on(TripActions.fetchTripsByUserIdSuccess, (state, { trips }) => ({
    ...state,
    trips,
    fetchedAt: new Date(),
  }))
);

export { tripReducer };
