import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';

import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType, User } from '@models';
import { UserService } from '@services';
import {
  createCarTrackProfile,
  fetchCurrentUser,
  fetchDocumentTypes,
  selectCurrentUser,
  selectDocumentTypes,
  selectUsersFetchedAt,
} from '@state';
import { AssignedVehicleComponent } from './assigned-vehicle/assigned-vehicle.component';
import { DriverBehaviorComponent } from './driver-behavior/driver-behavior.component';
import { TripsComponent } from './trips/trips.component';
import { UserAddressComponent } from './user-address/user-address.component';
import { UserDocumentsComponent } from './user-documents/user-documents.component';
import { UserExpensesComponent } from './user-expenses/user-expenses.component';
import { UserInspectionsComponent } from './user-inspections/user-inspections.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    UserProfileComponent,
    UserAddressComponent,
    UserDocumentsComponent,
    AssignedVehicleComponent,
    TripsComponent,
    DriverBehaviorComponent,
    UserInspectionsComponent,
    UserExpensesComponent,
  ],
  templateUrl: './user-details.page.html',
  styleUrl: './user-details.page.scss',
})
export class UserDetailsPage implements OnInit {
  public $user = this.store.select(selectCurrentUser);
  public $usersFetched = this.store.select(selectUsersFetchedAt);

  public currentTab: string = 'profile';
  public approvedOnlyTabs: string[] = [
    'trips',
    'driver-behavior',
    'inspections',
  ];
  private documentTypes: DocumentType[] = [];
  private user: User | null = null;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['tab']) this.currentTab = params['tab'];
    });
  }

  ngOnInit(): void {
    this.store.dispatch(fetchCurrentUser());
    this.store.dispatch(fetchDocumentTypes());
    this.store.select(selectDocumentTypes).subscribe((documentTypes) => {
      this.documentTypes = documentTypes;
    });
    this.$user.subscribe((user: User | undefined) => {
      if (user) {
        this.user = user;
      }
    });
  }

  public setTab(tab: string): void {
    if (this.approvedOnlyTabs.includes(tab) && !this.user?.vehicle) {
      return;
    }

    this.router.navigate([], { queryParams: { tab } });
  }

  public onCreateCarTrackProfile(): void {
    if (this.user)
      this.store.dispatch(createCarTrackProfile({ user: this.user }));
  }

  public isUserApproved(user: User): boolean {
    return UserService.isUserApproved(user, this.documentTypes);
  }

  public isProfileApproved(user: User): boolean {
    return UserService.isUserProfileApproved(user);
  }

  public isAddressApproved(user: User): boolean {
    return UserService.isUserAddressApproved(user);
  }

  public areDocumentsApproved(user: User): boolean {
    return UserService.areUserDocumentsApproved(user, this.documentTypes);
  }

  public hasCartTrackAccount(user: User): boolean {
    return !!user?.carTrackDriverId;
  }

  public hasAssignedVehicle(user: User): boolean {
    return !!user?.vehicle;
  }
}
