import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { DisplayInputComponent } from '@components';

import { Incident } from '@models';

@Component({
  selector: 'app-incident-summary',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    DisplayInputComponent,
    MatProgressSpinnerModule,
    MatGridListModule,
  ],
  templateUrl: './incident-summary.component.html',
  styleUrl: './incident-summary.component.scss',
})
export class IncidentSummeryComponent {
  @Input() incident: Incident | null = null;

  public boolToString(value: boolean): string {
    return value ? 'Yes' : 'No';
  }
}
