import { createReducer, on } from '@ngrx/store';

import { StateUtils } from '@state/state-utils';
import * as ExpenseActions from './expense.actions';
import { ExpenseState, initialExpenseState } from './expense.state';

const expenseReducer = createReducer<ExpenseState>(
  initialExpenseState,

  on(ExpenseActions.createExpenseSuccess, (state, { expense }) => ({
    ...state,
    expenses: StateUtils.combineState(state.expenses, expense),
  })),
  on(ExpenseActions.fetchExpensesSuccess, (state, { expenses }) => ({
    ...state,
    expenses,
    fetchedAt: new Date(),
  })),
  on(ExpenseActions.fetchExpenseByIdSuccess, (state, { expense }) => ({
    ...state,
    expenses: StateUtils.combineState(state.expenses, expense),
    fetchedAt: new Date(),
  })),
  on(ExpenseActions.updateExpenseSuccess, (state, { expense }) => ({
    ...state,
    expenses: StateUtils.combineState(state.expenses, expense),
  })),
  on(ExpenseActions.deleteExpenseSuccess, (state, { id }) => ({
    ...state,
    expenses: state.expenses.filter((x) => x.id !== id),
  })),
  on(
    ExpenseActions.fetchExpensesFailed,
    ExpenseActions.fetchExpenseByIdFailed,
    ExpenseActions.fetchExpensesByUserIdFailed,
    (state, { error }) => ({
      ...state,
      fetchedAt: new Date(),
    })
  ),

  on(ExpenseActions.createUserExpenseSuccess, (state, { userId, expense }) => ({
    ...state,
    userExpenses: {
      ...state.userExpenses,
      [userId]: StateUtils.combineState(
        state.userExpenses[userId] || [],
        expense
      ),
    },
  })),

  on(
    ExpenseActions.fetchExpensesByUserIdSuccess,
    (state, { userId, expenses }) => ({
      ...state,
      userExpenses: {
        ...state.userExpenses,
        [userId]: StateUtils.combineStateArr(
          state.userExpenses[userId] || [],
          expenses
        ),
      },
      fetchedAt: new Date(),
    })
  ),

  on(
    ExpenseActions.deleteUserExpenseSuccess,
    (state, { userId, expenseId }) => ({
      ...state,
      userExpenses: {
        ...state.userExpenses,
        [userId]: state.userExpenses[userId].filter((x) => x.id !== expenseId),
      },
    })
  )
);

export { expenseReducer };
