import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
  fetchInspectionImageTypes,
  selectInspectionImageTypes,
  selectInspectionImageTypesFetchedAt,
} from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './inspection-image-types.page.html',
  styleUrl: './inspection-image-types.page.scss',
})
export class InspectionImageTypesPage implements OnInit {
  columns: string[] = ['name', 'description'];

  $inspectionImageTypesFetchedAt = this.store.select(
    selectInspectionImageTypesFetchedAt
  );
  $inspectionImageTypes = this.store.select(selectInspectionImageTypes);

  constructor(private store: Store, private router: Router) {}

  public ngOnInit(): void {
    this.store.dispatch(fetchInspectionImageTypes());
  }

  public onAddInspectionImageTypeClicked(): void {
    this.router.navigate([`/inspection-image-types/new`]);
  }

  public onRowClicked(id: string): void {
    this.router.navigate([`/inspection-image-types/${id}`]);
  }
}
