import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Incident, User } from '@models';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

@Component({
  selector: 'app-user-incidences',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-incidences.component.html',
  styleUrls: ['./user-incidences.component.scss'],
})
export class UserIncidentsComponent implements AfterViewInit, OnChanges {
  @Input() public users: User[] | null = [];
  @Input() public incidents: Incident[] | null = [];

  @ViewChild('incidencesChart')
  private barChartRef!: ElementRef<HTMLCanvasElement>;
  private barChart!: Chart<'bar'>;

  ngAfterViewInit(): void {
    this.createBarChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.barChart) return;
    this.barChart.destroy();
    this.createBarChart();
  }

  private createBarChart(): void {
    const { userNames, incidentCounts } = this.getIncidentsByUser();

    this.barChart = new Chart(this.barChartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: userNames,
        datasets: [
          {
            label: 'Incidents per User',
            data: incidentCounts,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            title: { display: true, text: 'Number of Incidents' },
            beginAtZero: true,
          },
          y: {
            title: { display: true, text: 'User' },
          },
        },
      },
    });
  }

  private getIncidentsByUser(): {
    userNames: string[];
    incidentCounts: number[];
  } {
    const userIncidentCounts = new Map<string, number>();

    this.users?.forEach((user) => {
      const userId = user.id;

      if (!user.profile?.firstName && !user.profile?.lastName) {
        return;
      }

      const userName = `${user.profile.firstName || ''}`.trim();

      const incidentCount =
        this.incidents?.filter((incident) => incident.userId === userId)
          .length || 0;

      userIncidentCounts.set(userName, incidentCount);
    });

    const sortedEntries = Array.from(userIncidentCounts.entries()).sort(
      (a, b) => b[1] - a[1]
    );

    const topEntries = sortedEntries.slice(0, 10);

    const userNames = topEntries.map(([userName]) => userName);
    const incidentCounts = topEntries.map(([, count]) => count);

    return { userNames, incidentCounts };
  }
}
