import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TripState } from './trip.state';

export const selectTripState = createFeatureSelector<TripState>('trip');

export const selectTrips = createSelector(
  selectTripState,
  (state: TripState) => state.trips
);

export const selectTripsFetchedAt = createSelector(
  selectTripState,
  (state: TripState) => state.fetchedAt
);
