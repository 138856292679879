import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { IncidentImage } from '@models';
import { UtilService } from '@services';

@Component({
  selector: 'app-incident-images',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './incident-images.component.html',
  styleUrl: './incident-images.component.scss',
})
export class IncidentImagesComponent implements OnChanges {
  @Input() images: IncidentImage[] | null = null;
  @Input() imageType: String | null = null;

  public imageUrls: string[] = [];
  public imageIndex: number = 0;
  public imageUrl: SafeResourceUrl | string = '';

  public loading: boolean = false;
  public error: string = '';

  constructor(private sanitizer: DomSanitizer, private utils: UtilService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.images && this.images.length) {
      this.imageUrls = this.images.map((image) => image.imageUrl);
      this.populateDocumentAndDocumentType();
    }
  }

  private populateDocumentAndDocumentType(): void {
    const url = this.imageUrls[this.imageIndex] ?? '';

    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public nextDocument(): void {
    this.imageIndex = (this.imageIndex + 1) % this.imageUrls.length;
    this.populateDocumentAndDocumentType();
  }

  public previousDocument(): void {
    const size = this.imageUrls.length;
    const index = this.imageIndex;
    this.imageIndex = (index + size - 1) % size;
    this.populateDocumentAndDocumentType();
  }
}
