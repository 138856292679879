import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { IncidentService } from '@services';
import { selectRouteState } from '@state/route';
import * as IncidentActions from './incident.actions';

@Injectable()
export class IncidentEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private incidentService: IncidentService
  ) {}

  fetchIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentActions.fetchIncidents),
      switchMap(() =>
        from(this.incidentService.get()).pipe(
          map((incidents) =>
            IncidentActions.fetchIncidentsSuccess({ incidents })
          ),
          catchError((error) =>
            of(IncidentActions.fetchIncidentsFailed({ error }))
          )
        )
      )
    )
  );

  fetchIncidentById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentActions.fetchIncidentById),
      switchMap(({ incidentId }) =>
        from(this.incidentService.getById(incidentId)).pipe(
          map((incident) =>
            IncidentActions.fetchIncidentByIdSuccess({ incident })
          ),
          catchError((error) =>
            of(IncidentActions.fetchIncidentByIdFailed({ error }))
          )
        )
      )
    )
  );

  fetchCurrentIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentActions.fetchCurrentIncident),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const incidentId = route.params['incidentId'];
        return of(IncidentActions.fetchIncidentById({ incidentId }));
      })
    )
  );
}
