import { Expense } from '@models';

export interface ExpenseState {
  expenses: Expense[];
  userExpenses: { [userId: string]: Expense[] };
  fetchedAt: Date | null;
}

export const initialExpenseState: ExpenseState = {
  expenses: [],
  userExpenses: {},
  fetchedAt: null,
};
