import { Injectable } from '@angular/core';

import { Incident } from '@models';
import { CrudService, HttpService } from '@services';

@Injectable({ providedIn: 'root' })
export class IncidentService extends CrudService<Incident> {
  constructor(http: HttpService) {
    super('incident', http);
  }
}
