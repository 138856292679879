<div class="d-flex">
  <mat-card class="w-100 me-2">
    <mat-card-content>
      <app-sign-ups [users]="$users | async"> </app-sign-ups>
    </mat-card-content>
  </mat-card>

  <mat-card class="w-100 ms-2">
    <mat-card-content>
      <app-active-users [actions]="$actions | async"></app-active-users>
    </mat-card-content>
  </mat-card>
</div>
<div class="d-flex mt-3">
  <mat-card class="w-100 me-2">
    <mat-card-content>
      <app-users-by-country [users]="$users | async"> </app-users-by-country>
    </mat-card-content>
  </mat-card>
  <mat-card class="w-100 ms-2">
    <mat-card-content>
      <app-user-incidences
        [users]="$users | async"
        [incidents]="$incidents | async"
      >
      </app-user-incidences>
    </mat-card-content>
  </mat-card>
</div>
