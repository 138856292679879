import { Injectable } from '@angular/core';

import { Expense } from '@models';
import { CrudService, HttpService } from '@services';

@Injectable({ providedIn: 'root' })
export class ExpenseService extends CrudService<Expense> {
  constructor(http: HttpService) {
    super('expense', http);
  }

  async addUserExpense(userId: string, expenseId: string): Promise<Expense> {
    const res = await this.http.post<Expense>(`${this.url}/user`, {
      userId,
      expenseId,
    });
    if (!res.success) throw new Error(res.message);
    return res.data;
  }

  async getUserExpenses(userId: string): Promise<Expense[]> {
    const res = await this.http.get<Expense[]>(`${this.url}/user/${userId}`);
    if (!res.success) throw new Error(res.message);
    return res.data;
  }

  async deleteUserExpense(userId: string, expenseId: string): Promise<void> {
    const url = `${this.url}/${expenseId}/user/${userId}`;
    const res = await this.http.delete(url);
    if (!res.success) throw new Error(res.message);
  }
}
