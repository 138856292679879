import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TripService } from '@services';
import * as TripActions from './trip.actions';

@Injectable()
export class TripEffects {
  constructor(private actions$: Actions, private tripService: TripService) {}

  fetchTrips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.fetchTrips),
      switchMap(({ dateRange }) =>
        from(this.tripService.getTrips(dateRange)).pipe(
          map((trips) => TripActions.fetchTripsSuccess({ trips })),
          catchError((error) => of(TripActions.fetchTripsFailed({ error })))
        )
      )
    )
  );

  fetchTripsByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.fetchTripsByUserId),
      switchMap(({ userId, dateRange }) =>
        from(this.tripService.getTripsByUserId(userId, dateRange)).pipe(
          map((trips) => TripActions.fetchTripsByUserIdSuccess({ trips })),
          catchError((error) =>
            of(TripActions.fetchTripsByUserIdFailed({ error }))
          )
        )
      )
    )
  );
}
