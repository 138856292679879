@let expenses = ($expenses | async) || [];
<!---->
@let fetched = ($fetched | async);
<!---->

@if (!fetched ) {
<div class="text-center mt-4">
  <div class="d-flex justify-content-center">
    <mat-progress-spinner mode="indeterminate" color="primary" diameter="30" />
  </div>
  <div class="ms-2">Fetching user expenses...</div>
</div>
} @if (fetched && expenses.length) {
<div class="mt-3">Which expenses apply to this user?</div>

@if (error) {
<div class="alert alert-danger mt-3">{{ error }}</div>
}
<!---->
@for (expense of expenses; track $index) {
<mat-card class="mt-3" appearance="outlined">
  <mat-card-content>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div>{{ expense.name }}</div>
        <div class="small">{{ expense.description }}</div>
      </div>
      <div>
        @if (loadingExpenseId == expense.id) {
        <mat-progress-spinner
          mode="indeterminate"
          color="primary"
          diameter="30"
        />
        } @else {
        <mat-slide-toggle
          [checked]="doesUserHaveExpense(expense)"
          (toggleChange)="onToggleExpense(expense)"
        >
        </mat-slide-toggle>
        }
      </div>
    </div>
  </mat-card-content>
</mat-card>
}
<!---->
}
<!---->
@if (fetched && !expenses.length) {
<div class="d-flex justify-content-center align-items-center h-100">
  <div class="text-center">
    <div class="d-flex align-items-center">
      <mat-icon class="me-2">payment</mat-icon>
      <div>There are no expenses</div>
    </div>
    <button
      mat-raised-button
      color="primary"
      class="mt-3"
      (click)="onNavToExpensesClicked()"
    >
      Add Expenses
    </button>
  </div>
</div>
}
