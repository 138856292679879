import { createAction, props } from '@ngrx/store';

import { DateRange } from '@enums';
import { Trip } from '@models';

// fetch trips
export const fetchTrips = createAction(
  '[Trip] Fetch Trips',
  props<{ dateRange: DateRange }>()
);
export const fetchTripsSuccess = createAction(
  '[Trip] Fetch Trips Success',
  props<{ trips: Trip[] }>()
);
export const fetchTripsFailed = createAction(
  '[Trip] Fetch Trips Failed',
  props<{ error: Error }>()
);

// fetch trips by userId
export const fetchTripsByUserId = createAction(
  '[Trip] Fetch Trips By User Id',
  props<{ userId: string; dateRange: DateRange }>()
);
export const fetchTripsByUserIdSuccess = createAction(
  '[Trip] Fetch Trips By User Id Success',
  props<{ trips: Trip[] }>()
);
export const fetchTripsByUserIdFailed = createAction(
  '[Trip] Fetch Trips By User Id Failed',
  props<{ error: Error }>()
);
