import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { VehicleService } from '@services';
import { selectRouteState } from '@state/route';
import * as VehicleActions from './vehicle.actions';

@Injectable()
export class VehicleEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private vehicleService: VehicleService
  ) {}

  fetchVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.fetchVehicles),
      switchMap(() =>
        from(this.vehicleService.get()).pipe(
          map((vehicles) => VehicleActions.fetchVehiclesSuccess({ vehicles })),
          catchError((error) =>
            of(VehicleActions.fetchVehiclesFailed({ error }))
          )
        )
      )
    )
  );

  fetchVehicleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.fetchVehicleById),
      switchMap(({ vehicleId }) =>
        from(this.vehicleService.getById(vehicleId)).pipe(
          map((vehicle) => VehicleActions.fetchVehicleByIdSuccess({ vehicle })),
          catchError((error) =>
            of(VehicleActions.fetchVehicleByIdFailed({ error }))
          )
        )
      )
    )
  );

  fetchCurrectVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.fetchCurrentVehicle),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const vehicleId = route.params['vehicleId'];
        return of(VehicleActions.fetchVehicleById({ vehicleId }));
      })
    )
  );

  assignUserToVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.assignUserToVehicle),
      switchMap(({ vehicleId, userId }) =>
        from(this.vehicleService.assignUserToVehicle(vehicleId, userId)).pipe(
          map((vehicle) =>
            VehicleActions.assignUserToVehicleSuccess({ vehicle })
          ),
          catchError((error) =>
            of(VehicleActions.assignUserToVehicleFailed({ error }))
          )
        )
      )
    )
  );

  removeUserFromVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.removeUserFromVehicle),
      switchMap(({ vehicleId, userId }) =>
        from(this.vehicleService.removeUserFromVehicle(vehicleId, userId)).pipe(
          map((vehicle) =>
            VehicleActions.removeUserFromVehicleSuccess({ vehicle })
          ),
          catchError((error) =>
            of(VehicleActions.removeUserFromVehicleFailed({ error }))
          )
        )
      )
    )
  );

  fetchVehicleEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.fetchVehicleEvents),
      switchMap(({ carTrackDriverId, dateRange }) =>
        from(
          this.vehicleService.getVehicleEvents(carTrackDriverId, dateRange)
        ).pipe(
          map((vehicleEvents) =>
            VehicleActions.fetchVehicleEventsSuccess({ vehicleEvents })
          ),
          catchError((error) =>
            of(VehicleActions.fetchVehicleEventsFailed({ error }))
          )
        )
      )
    )
  );
}
