import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { InspectionService } from '@services';
import { selectRouteState } from '@state/route';
import * as InspectionActions from './inspection.actions';

@Injectable()
export class InspectionEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private inspectionService: InspectionService
  ) {}

  //Read
  fetchInspections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionActions.fetchInspections),
      switchMap(() =>
        from(this.inspectionService.get()).pipe(
          map((inspections) =>
            InspectionActions.fetchInspectionsSuccess({ inspections })
          ),
          catchError((error) =>
            of(InspectionActions.fetchInspectionsFailed({ error }))
          )
        )
      )
    )
  );

  fetchInspection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionActions.fetchInspectionById),
      switchMap(({ id }) =>
        from(this.inspectionService.getById(id)).pipe(
          map((inspection) =>
            InspectionActions.fetchInspectionByIdSuccess({ inspection })
          ),
          catchError((error) =>
            of(InspectionActions.fetchInspectionByIdFailed({ error }))
          )
        )
      )
    )
  );

  fetchCurrentUserInspection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionActions.fetchCurrentUserInspections),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const userId = route.params['userId'];

        return from(this.inspectionService.getUserInspections(userId)).pipe(
          map((inspections) =>
            InspectionActions.fetchInspectionsSuccess({ inspections })
          ),
          catchError((error) =>
            of(InspectionActions.fetchInspectionsFailed({ error }))
          )
        );
      })
    )
  );
}
