export enum CarTrackEventType {
  SPEEDING_START = 'SPEEDING_START',
  SPEEDING_END = 'SPEEDING_END',
  FORWARD_COLLISION_WARNING = 'FORWARD_COLLISION_WARNING',
  PEDESTRIAN_COLLISION_WARNING = 'PEDESTRIAN_COLLISION_WARNING',
  URBAN_FORWARD_COLLISION_WARNING = 'URBAN_FORWARD_COLLISION_WARNING',
  SHOCK = 'SHOCK',
  HARSH_ACCELERATION = 'HARSH_ACCELERATION',
  HARSH_BRAKING = 'HARSH_BRAKING',
  HARSH_CORNERING = 'HARSH_CORNERING',
}
