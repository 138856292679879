@if (imageUrls.length) {
<div class="mb-4">{{ imageType }}</div>
<div class="d-flex justify-content-between align-items-center">
  <div class="d-flex justify-content-center2">
    {{ imageIndex + 1 + "/" + imageUrls.length }}
  </div>
</div>

<div class="d-flex justify-content-center align-items-center my-3">
  <button mat-icon-button class="me-3" (click)="previousDocument()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div class="document-holder">
    <img [src]="imageUrl" />
  </div>
  <button mat-icon-button class="ms-3" (click)="nextDocument()">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div class="image-carousel d-flex justify-content-center mt-3">
  <div
    *ngFor="let img of imageUrls; let i = index"
    class="carousel-item"
    [ngClass]="{ 'active-image': i === imageIndex }"
    (click)="imageIndex = i"
  >
    <img [src]="img" alt="Image {{ i + 1 }}" class="thumbnail" />
  </div>
</div>
} @if (!imageUrls.length) {
<div class="d-flex justify-content-center align-items-center h-100">
  <mat-icon class="me-2">upload_file</mat-icon>
  <div>This user has not uploaded any images for this section</div>
</div>
}
