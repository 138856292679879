import { Injectable } from '@angular/core';

import { Status } from '@enums';
import { Profile } from '@models';
import { CrudService, HttpService } from '@services';
import { environment } from '../../../environment/environment';

@Injectable({ providedIn: 'root' })
export class ProfileService extends CrudService<Profile> {
  constructor(http: HttpService) {
    super('profile', http);
  }

  async setProfileStatus(profile: Profile, status: Status): Promise<Profile> {
    const endpoint = `${environment.apiUrl}/profile/status`;
    const response = await this.http.post<Profile>(endpoint, {
      profileId: profile.id,
      status,
    });

    if (!response.success) throw new Error(response.message);
    return response.data;
  }
}
