import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Vehicle } from '@models';
import { RouteState, selectRouteState } from '@state/route';
import { VehicleState } from './vehicle.state';

export const selectVehicleState =
  createFeatureSelector<VehicleState>('vehicle');

export const selectVehicles = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.vehicles
);

export const selectVehicleById = (vehicleId: string) =>
  createSelector(selectVehicles, (vehicles: Vehicle[]) =>
    vehicles.find((vehicle) => vehicle.id === vehicleId)
  );

export const selectCurrentVehicle = createSelector(
  selectVehicles,
  selectRouteState,
  (vehicles: Vehicle[], route: RouteState) => {
    return vehicles.find((x) => x.id === route.params['vehicleId']);
  }
);

export const selectVehiclesFetchedAt = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.fetchedAt
);

export const selectVehicleEvents = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.vehicleEvents
);

export const selectVehicleEventsFetchedAt = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.eventsFetchedAt
);
