import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Incident } from '@models';
import { IncidentState, RouteState, selectRouteState } from '@state';

export const selectIncidentState =
  createFeatureSelector<IncidentState>('incident');

export const selectIncidents = createSelector(
  selectIncidentState,
  (state: IncidentState) => state.incidents
);

export const selectIncidentsFetchedAt = createSelector(
  selectIncidentState,
  (state: IncidentState) => state.fetchedAt
);

export const selectCurrentIncident = createSelector(
  selectIncidents,
  selectRouteState,
  (incidents: Incident[], route: RouteState) => {
    return incidents.find((x) => x.id === route.params['incidentId']);
  }
);

export const selectIncidentById = (incidentId: string) =>
  createSelector(selectIncidents, (incidents: Incident[]) =>
    incidents.find((incident) => incident.id === incidentId)
  );
