@if (incident) {
<display-input title="Incident Type" [value]="incident.type" />
<div class="row">
  <div class="col-6">
    <display-input
      title="Incident Date"
      [value]="(incident.date | date : 'short') || undefined"
    />
  </div>
  <div class="col-6">
    <display-input
      title="Incident Time"
      [value]="(incident.date | date : 'shortTime') || undefined"
    />
  </div>
</div>
<display-input title="Incident Location" [value]="incident.location" />
<display-input title="Incident Description" [value]="incident.description" />
<div class="row">
  <div class="col-6">
    <display-input
      title="Vehicle Registration"
      [value]="incident.vehicleRegistration"
    />
  </div>
  <div class="col-6">
    <display-input
      title="Vehicle Damage"
      [value]="boolToString(incident.vehicleDamage)"
    />
  </div>
</div>

<display-input
  title="Damage Description"
  [value]="incident.damageDescription"
/>

<div class="row">
  <div class="col-6">
    <display-input
      title="Injuries Involved"
      [value]="boolToString(incident.injuriesInvolved)"
    />
  </div>
  <div class="col-6">
    <display-input
      title="Fatalities"
      [value]="boolToString(incident.fatalitiesInvolved)"
    />
  </div>
</div>

<display-input
  title="Injuries Description"
  [value]="incident.injuriesDescription"
/>
<display-input
  title="Actions Taken"
  [value]="incident.actionsTakenDescription"
/>
@for (witness of incident.witnesses; track witness) {
<div class="row">
  <div class="col-6">
    <display-input title="Witness Name" [value]="witness.name" />
  </div>
  <div class="col-6">
    <display-input title="Witness Number" [value]="witness.number" />
  </div>
</div>

} }
