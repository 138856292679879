import { Routes } from '@angular/router';

import { LayoutComponent } from '@components';
import {
  ActionsPage,
  DashboardPage,
  DocumentTypePage,
  DocumentTypesPage,
  ExpensePage,
  ExpensesPage,
  IncidentDetailsPage,
  IncidentsPage,
  InspectionImageTypePage,
  InspectionImageTypesPage,
  LoginPage,
  MapPage,
  UserDetailsPage,
  UsersPage,
  VehicleDetailsPage,
  VehiclesPage,
} from '@pages';
import { AuthGuard } from '@services';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardPage,
        data: { breadcrumb: 'Dashboard' },
      },
      {
        path: 'users',
        component: UsersPage,
        data: { breadcrumb: 'Users' },
      },
      {
        path: 'users/:userId',
        component: UserDetailsPage,
        data: { breadcrumb: 'User Details' },
      },
      {
        path: 'document-types',
        component: DocumentTypesPage,
        data: { breadcrumb: 'Document Types' },
      },
      {
        path: 'document-types/:documentTypeId',
        component: DocumentTypePage,
        data: { breadcrumb: 'Document Type' },
      },
      {
        path: 'actions',
        component: ActionsPage,
        data: { breadcrumb: 'Actions' },
      },
      {
        path: 'vehicles',
        component: VehiclesPage,
        data: { breadcrumb: 'Vehicles' },
      },
      {
        path: 'vehicles/:vehicleId',
        component: VehicleDetailsPage,
        data: { breadcrumb: 'Vehicle' },
      },
      {
        path: 'incidents',
        component: IncidentsPage,
        data: { breadcrumb: 'Incidents Page' },
      },
      {
        path: 'incidents/:incidentId',
        component: IncidentDetailsPage,
        data: { breadcrumb: 'Incident Details' },
      },
      {
        path: 'map',
        component: MapPage,
        data: { breadcrumb: 'Map Page' },
      },
      {
        path: 'inspection-image-types',
        component: InspectionImageTypesPage,
        data: { breadcrumb: 'Inspection Images' },
      },
      {
        path: 'inspection-image-types/:inspectionImageTypeId',
        component: InspectionImageTypePage,
        data: { breadcrumb: 'Inspection Image' },
      },
      {
        path: 'expenses',
        component: ExpensesPage,
        data: { breadcrumb: 'Expenses' },
      },
      {
        path: 'expenses/:expenseId',
        component: ExpensePage,
        data: { breadcrumb: 'Expense' },
      },
    ],
  },
  { path: 'auth/login', component: LoginPage },
  { path: '**', redirectTo: '' },
];
