@let vehicleEventsFetchedAt = $vehicleEventsFetchedAt | async;
@if(!!vehicleEventsFetchedAt){
<div class="container">
  <h5>Driver Behavior Metrics</h5>
  <div style="position: absolute">
    <mat-form-field>
      <mat-label>Date Range</mat-label>
      <mat-select
        [(value)]="selectedDateRange"
        (selectionChange)="filterByDateRange()"
      >
        @for(dateRange of dateRanges; track dateRange){
        <mat-option [value]="dateRange">{{ dateRange }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  @if(!!vehicleEvents.length){
  <div class="chart-container">
    <canvas height="200" #radarChart></canvas>
  </div>
  <div class="chart-container">
    <canvas height="40" #lineChart></canvas>
  </div>
  <div>
    <div class="metrics-grid">
      <div class="metric-item">
        <p>Collision Warnings:</p>
        <h2 [appCountUp]="collisions" [duration]="1000"></h2>
      </div>
      <div class="metric-item">
        <p>Shock Warnings:</p>
        <h2 [appCountUp]="shockWarnings" [duration]="1000"></h2>
      </div>
      <div class="metric-item">
        <p>Harsh Braking Warnings:</p>
        <h2 [appCountUp]="harshBrakingWarnings" [duration]="1000"></h2>
      </div>
      <div class="metric-item">
        <p>Harsh Acceleration Warnings:</p>
        <h2 [appCountUp]="harshAccelerationWarnings" [duration]="1000"></h2>
      </div>
      <div class="metric-item">
        <p>Harsh Cornering Warnings:</p>
        <h2 [appCountUp]="harshCorneringWarnings" [duration]="1000"></h2>
      </div>
      <div class="metric-item">
        <p>Speeding Warnings:</p>
        <h2 [appCountUp]="speedingWarnings" [duration]="1000"></h2>
      </div>
    </div>
    <div #score class="driver-score">
      <p>Driver Score:</p>
      @if(driverScore){
      <div style="display: flex; justify-content: center">
        <h1 [appCountUp]="driverScore" [duration]="1000"></h1>
        <h1>/100</h1>
      </div>
      }
    </div>
  </div>
  }
</div>
@if(!vehicleEvents.length){
<div class="noMetricContainer">
  <h3 style="color: gray">No Vehicle Events</h3>
</div>
} } @if (!vehicleEventsFetchedAt) {
<div class="spinner-container">
  <mat-spinner></mat-spinner>
</div>
}
