import { ActionReducerMap } from '@ngrx/store';
import { State } from '@state';

import { actionReducer } from './action/action.reducer';
import { authReducer } from './auth/auth.reducer';
import { documentTypeReducer } from './document-type/document-type.reducer';
import { expenseReducer } from './expense';
import { incidentReducer } from './incident';
import { inspectionReducer } from './inspection';
import { inspectionImageTypeReducer } from './inspection-image-type';
import { tripReducer } from './trip';
import { userReducer } from './user/user.reducer';
import { vehicleReducer } from './vehicle';

export const reducers: ActionReducerMap<State> = {
  auth: authReducer,
  user: userReducer,
  documentType: documentTypeReducer,
  action: actionReducer,
  vehicle: vehicleReducer,
  trip: tripReducer,
  inspectionImageType: inspectionImageTypeReducer,
  inspection: inspectionReducer,
  incident: incidentReducer,
  expense: expenseReducer,
};
