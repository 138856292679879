import { DateRange } from '@enums';

export abstract class VehicleUtils {
  public static fromDateRange(dateRange: DateRange): {
    from: string;
    to: string;
  } {
    let from = '';
    const to = VehicleUtils.getDateFormat(0);

    switch (dateRange) {
      case DateRange.TODAY:
        from = VehicleUtils.getDateFormat(1);
        break;
      case DateRange.LAST_7_DAYS:
        from = VehicleUtils.getDateFormat(7);
        break;
      case DateRange.LAST_30_DAYS:
        from = VehicleUtils.getDateFormat(30);
        break;
      case DateRange.LAST_90_DAYS:
        from = VehicleUtils.getDateFormat(90);
        break;
      default:
        from = VehicleUtils.getDateFormat(7);
        break;
    }
    return { from, to };
  }

  private static getDateFormat(offset: number): string {
    const date = new Date(new Date().setDate(new Date().getDate() - offset));
    const tzOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - tzOffset).toISOString().split('T')[0];
  }
}
