import { Injectable } from '@angular/core';
import { DateRange } from '@enums';
import { Trip } from '@models';
import { VehicleUtils } from '@utils';
import { environment } from '../../../environment/environment';
import { CrudService } from '../crud/crud.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class TripService extends CrudService<Trip> {
  constructor(http: HttpService) {
    super('trip', http);
  }

  async getTrips(dateRange: DateRange): Promise<Trip[]> {
    const { from, to } = VehicleUtils.fromDateRange(dateRange);
    const endpoint = `${environment.apiUrl}/trip/all/${from}/${to}`;
    const response = await this.http.get<Trip[]>(endpoint);
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  async getTripsByUserId(
    userId: string,
    dateRange: DateRange
  ): Promise<Trip[]> {
    const { from, to } = VehicleUtils.fromDateRange(dateRange);
    const endpoint = `${environment.apiUrl}/trip/user/${userId}/${from}/${to}`;
    const response = await this.http.get<Trip[]>(endpoint);
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  // override methods which are not available for this service
  override create(data: Partial<Trip>): Promise<Trip> {
    throw new Error('Method not available.');
  }
  override get(): Promise<Trip[]> {
    throw new Error('Method not available.');
  }

  override getById(id: string): Promise<Trip> {
    throw new Error('Method not available.');
  }
  override update(data: Partial<Trip>): Promise<Trip> {
    throw new Error('Method not available.');
  }
  override delete(id: string): Promise<string> {
    throw new Error('Method not available.');
  }
}
