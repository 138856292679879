<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ inspectionImageType.id ? 'Update' : 'Create' }} Inspection Image
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    @if ($fetchedAt | async) {
    <form [formGroup]="form" class="mt-4">
      <mat-form-field class="w-100">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" type="text" />
      </mat-form-field>

      <mat-form-field class="w-100">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>

      @if (error) {
      <app-alert class="mt-3" type="error"> {{ error }} </app-alert>
      }

      <div class="d-flex align-items-center justify-content-end mt-3">
        @if (loading) {
        <mat-progress-spinner
          class="me-2"
          mode="indeterminate"
          color="primary"
          diameter="30"
        />
        }

        <div style="display: flex">
          @if (inspectionImageType.id) {
          <button
            mat-raised-button
            color="warn"
            class="px-4 me-3"
            [disabled]="loading"
            (click)="onDeleteInspectionImageType()"
          >
            Delete Inspection Image
          </button>
          }
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="px-4"
            [disabled]="loading"
            (click)="onFormSubmit()"
          >
            {{ inspectionImageType.id ? 'Update' : 'Create' }} Inspection Image
          </button>
        </div>
      </div>
    </form>
    }
    <!---->
    @if (!($fetchedAt | async)) {
    <div class="d-flex justify-content-center">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    }
  </mat-card-content>
</mat-card>
