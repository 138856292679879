import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { routes } from './app.routes';

import {
  ActionEffects,
  AuthEffects,
  DocumentTypeEffects,
  ExpenseEffects,
  IncidentEffects,
  InspectionEffects,
  InspectionImageTypeEffects,
  reducers,
  TripEffects,
  UserEffects,
  VehicleEffects,
} from '@state';
import { environment } from 'src/environment/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(),
    provideAnimationsAsync(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideDatabase(() => getDatabase()),
    provideStore({
      ...reducers,
      router: routerReducer,
    }),
    provideEffects([
      AuthEffects,
      UserEffects,
      DocumentTypeEffects,
      ActionEffects,
      VehicleEffects,
      TripEffects,
      InspectionEffects,
      InspectionImageTypeEffects,
      IncidentEffects,
      ExpenseEffects,
    ]),
    provideRouterStore(),
    provideStoreDevtools({
      maxAge: 25, // retains last 25 states
      logOnly: environment.production,
    }),
  ],
};
