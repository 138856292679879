import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

interface Err {
  error: Error;
}

@Injectable({ providedIn: 'root' })
export class UtilService {
  constructor(private actions: Actions, private store: Store) {}

  public actionsToPromise<T>(
    action: Action,
    successAction: ActionCreator<any, (props: T) => T & Action<any>>,
    failedAction: ActionCreator<any, (props: Err) => Err & Action<any>>
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const subs: Subscription[] = [];

      const successSub = this.actions
        .pipe(ofType(successAction))
        .subscribe((result: T) => {
          for (const sub of subs) sub.unsubscribe();
          resolve(result);
        });

      const failedSub = this.actions
        .pipe(ofType(failedAction))
        .subscribe((err) => {
          for (const sub of subs) sub.unsubscribe();
          reject(err.error);
        });

      subs.push(successSub);
      subs.push(failedSub);
      this.store.dispatch(action);
    });
  }

  public toPromise<T>(observable: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      observable.subscribe({ next: resolve, error: reject });
    });
  }

  public isJwtTokenExpired(token: string, offsetSeconds = 30): boolean {
    const expirationDate = this.getJwtExpirationDate(token);
    if (!expirationDate) return false; // not expired if no expiry date

    const now = new Date().valueOf() + offsetSeconds * 1000;
    const expiry = expirationDate.valueOf();
    return now > expiry;
  }

  private getJwtExpirationDate(token: string) {
    const decoded = this.decodeToken(token);

    if (typeof decoded.exp === 'undefined') {
      return null;
    }

    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decoded.exp);

    return expirationDate;
  }

  private decodeToken(token: string): any {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid JWT Token, expecting 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  private urlBase64Decode(input: string) {
    let output = input.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;

      case 2:
        output += '==';
        break;

      case 3:
        output += '=';
        break;

      default:
        throw new Error('Illegal base64url string!');
    }
    return window.decodeURIComponent(escape(window.atob(output)));
  }

  public static formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  public capitalizeFirstLetter(str: string): string {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getErrorMessage(err: any): string {
    if (err.error && err.error.message) {
      return err.error.message;
    } else if (err.message) {
      return err.message;
    } else {
      return 'An error occurred';
    }
  }
}
