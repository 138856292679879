import { createAction, props } from '@ngrx/store';

import { Expense } from '@models';

// Create Expense:
export const createExpense = createAction(
  '[Expense] Create Initiated',
  props<{ expense: Expense }>()
);
export const createExpenseSuccess = createAction(
  '[Expense] Create Success',
  props<{ expense: Expense }>()
);
export const createExpenseFailed = createAction(
  '[Expense] Create Failed',
  props<{ error: Error }>()
);

// Fetch Expenses:
export const fetchExpenses = createAction('[Expense] Fetch Initiated');
export const fetchExpensesSuccess = createAction(
  '[Expense] Fetch Success',
  props<{ expenses: Expense[] }>()
);
export const fetchExpensesFailed = createAction(
  '[Expense] Fetch Failed',
  props<{ error: Error }>()
);

// Fetch Expense By Id:
export const fetchExpenseById = createAction(
  '[Expense] Fetch By Id Initiated',
  props<{ id: string }>()
);
export const fetchCurrentExpense = createAction(
  '[Expense] Fetch Current By Id Initiated'
);
export const fetchExpenseByIdSuccess = createAction(
  '[Expense] Fetch By Id  Success',
  props<{ expense: Expense | null }>()
);
export const fetchExpenseByIdFailed = createAction(
  '[Expense] Fetch By Id  Failed',
  props<{ error: Error }>()
);

// Update Expense:
export const updateExpense = createAction(
  '[Expense] Update Initiated',
  props<{ expense: Expense }>()
);
export const updateExpenseSuccess = createAction(
  '[Expense] Update Success',
  props<{
    expense: Expense;
  }>()
);
export const updateExpenseFailed = createAction(
  '[Expense] Update Failed',
  props<{ error: Error }>()
);

// Delete Expense:
export const deleteExpense = createAction(
  '[Expense] Delete Initiated',
  props<{ id: string }>()
);
export const deleteExpenseSuccess = createAction(
  '[Expense] Delete Success',
  props<{ id: string }>()
);
export const deleteExpenseFailed = createAction(
  '[Expense] Delete Failed',
  props<{ error: Error }>()
);

/* User Expenses */
export const createUserExpense = createAction(
  '[Expense] Create User Expense Initiated',
  props<{ expenseId: string, userId: string }>()
);
export const createUserExpenseSuccess = createAction(
  '[Expense] Create User Expense Success',
  props<{ expense: Expense, userId: string }>()
);
export const createUserExpenseFailed = createAction(
  '[Expense] Create User Expense Failed',
  props<{ error: Error }>()
);

export const fetchExpensesByUserId = createAction(
  '[Expense] Fetch Expenses By User Id Initiated',
  props<{ userId: string }>()
);
export const fetchCurrentUserExpenses = createAction(
  '[Expense] Fetch By Current User Initiated'
);
export const fetchExpensesByUserIdSuccess = createAction(
  '[Expense] Fetch Expenses By User Id Success',
  props<{ expenses: Expense[], userId: string }>()
);
export const fetchExpensesByUserIdFailed = createAction(
  '[Expense] Fetch Expenses By User Id Failed',
  props<{ error: Error }>()
);

export const deleteUserExpense = createAction(
  '[Expense] Delete User Expense Initiated',
  props<{ expenseId: string, userId: string }>()
);
export const deleteUserExpenseSuccess = createAction(
  '[Expense] Delete User Expense Success',
  props<{ expenseId: string, userId: string }>()
);
export const deleteUserExpenseFailed = createAction(
  '[Expense] Delete User Expense Failed',
  props<{ error: Error }>()
);
